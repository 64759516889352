import styles from "./ChatWindow.module.css"

import { ChatAudioMessage, ChatMessageText, ChatVideoMessage } from "./ChatMessagges/ChatMessages";
import ChatInputMessage from "./ChatInputMessage/ChatInputMessage";
import ChatHeader from "./ChatHeader/ChatHeader";

export default function ChatWindow({onSelectMenuOption}) {
    return (
      <div className={styles.chatWindowContainer}>
        <ChatHeader onSelectMenuOption={onSelectMenuOption}/>
  
        <div className={styles.chatMessagesList}>
          <div className={styles.chatDate}>
            <p>Hoy, Abril 11</p>
          </div>
          <ChatMessageText
            avatarSrc={"https://randomuser.me/api/portraits/lego/1.jpg"}
            username="Cliente 1"
            time="09:18 AM"
            messageText="Hola buenas para unos zapaticos bien bacanos"
            isSent={false}
          />
  
        <ChatMessageText
          username="You"
          avatarSrc={"https://i.pinimg.com/564x/2d/34/f9/2d34f9f6a835ea240b9d6ba4c12dce15.jpg"}
          time="09:19 AM"
          messageText={
            <div>
              Se le tienen compadre {" "}
              <a href="https://www.instagram.com/p/Cq2AzG" target="_blank" rel="noopener noreferrer">
                https://www.instagram.com/p/
              </a>
            </div>
          }
          isSent={true}
        />
  
  
        <ChatMessageText
          username="Cliente 1"
          avatarSrc={"https://randomuser.me/api/portraits/lego/1.jpg"}
          time="09:20 AM"
          messageText={<><img src="image.png" alt="Linner" className={styles.messageImage} />
            Vi estos en la pagina, muy bacana x cierto, salu2 q valen?
              </>
            }
          isSent={false}
        />
  
        <ChatMessageText
          username="Cliente 1"
            avatarSrc={"https://randomuser.me/api/portraits/lego/1.jpg"}

          time="09:25 AM"
          messageText={
            <div>
              Tienes esta otra referencia? <br />
              <a href="https://www.instagram.com/p/Cq2AzG" target="_blank" rel="noopener noreferrer">
                https://www.instagram.com/p/Cq2AzGsadaayudasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdaayudaasdasdsadasdasdasd
              </a>
            </div>
          }
          isSent={false}
        />
        <ChatMessageText
          username="You"
          time="09:19 AM"
          avatarSrc={"https://i.pinimg.com/564x/2d/34/f9/2d34f9f6a835ea240b9d6ba4c12dce15.jpg"}
          messageText="20 millones brother"
          isSent={true}
        />
        <ChatAudioMessage
          avatarSrc={"https://randomuser.me/api/portraits/lego/1.jpg"}
          username="Cliente 1"
          time="09:30 AM"
          
          audioSrc="https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/Lil%20Tecca%20-%20DUI%20(Official%20Audio)%20-%20Lil%20Tecca.mp3?alt=media&token=0dde6991-f89a-44b5-bae3-0767ff2a31bb"
          isSent={false}
        />
        
        <ChatVideoMessage
          avatarSrc={"https://i.pinimg.com/564x/2d/34/f9/2d34f9f6a835ea240b9d6ba4c12dce15.jpg"}
          username="You"
          time="09:35 AM"
          videoSrc="https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/shoppexStores%2FJDfK9GpHVJldVzqFKRGt%2Fsection%2F19DgQGqvLnOKsOqgMU4r%2Farchive?alt=media&token=a7214314-5e4a-4bfc-b854-9037ec14e355"
          isSent={true}
        />
        </div>
        
        <ChatInputMessage/>
      </div>
    );
  }
