// import { InputSwitch } from "primereact/inputswitch";
import PropTypes from "prop-types";
import { useState, useEffect, useRef } from "react";
import { doc, deleteDoc, query, where,  collection } from "firebase/firestore";
import { CREATE, EDIT } from "../router/paths";
import { deleteObject, listAll, ref } from "firebase/storage";
import AttachImg from "../assets/Icons/AttachImg";
import { generateRandomId } from "../../utils/generateRandomId";
import ProductVariations from "../ProductVariations/ProductVariations";
import { db, storage } from "../../firebase/firebase";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText";
import { useTranslation } from "react-i18next";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect";
import CustomInputSwitch from "../FormComponents/CustomInputSwitch/CustomInputSwitch";
import CustomInputNumber from "../FormComponents/CustomInputNumber/CustomInputNumber";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext";
import DeleteButton from "../DeleteButton/DeleteButton";
import CustomConfirmDialog from "../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog";
import MainButton from "../MainButton/MainButton";
import CustomMultiSelect from "../CustomMultiSelect/CustomMultiSelect";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import toastFunction from "../../utils/toastFunction";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD.js"
import { Toast } from "primereact/toast";
import CustomTextArea from "../FormComponents/CustomTextArea/CustomTextArea.jsx";
import { getObjectFromLocalStorage, removeObjectFromLocalStorage, saveObjectToLocalStorage } from "../../utils/localStorage.js";

const ProductForm = ({ mode, categoryProductsData, selectedProduct, toast, onClose }) => {
  const { getDocumentsByQuery } = useFirebaseCRUD();
  const { userData, userSellerOwnerData } = useAuthContext();
  const { globalLoading, setGlobalLoading } = useLoaderContext();

  const [ownerShippingAddress, setOwnerShippingAddress] = useState(userSellerOwnerData?.shipping_address || userData?.shipping_address || []);

  const sellerOwnerData = userSellerOwnerData || userData;
  const isCoworker = !userData.isAdmin  && userData.related_seller_id && userData.is_coworker;
  const userOwnerData = isCoworker && userSellerOwnerData ? userSellerOwnerData : userData;

  const isInsider =( userData?.user_custom_claims?.type === "insider" || userData?.user_custom_claims?.type === "master")  ? true : false;
  const { t } = useTranslation("Products");
  const HANDLEEDITEPRODUCT = "https://us-central1-shoppex-aa709.cloudfunctions.net/editProduct";
  const HANDLEPROCESSIMGS = "https://us-central1-shoppex-aa709.cloudfunctions.net/processImages";
  // console.log("selectedProduct", selectedProduct)

  const initialState = {
    id: selectedProduct.id || generateRandomId(),
    product_name: "",
    main_img: {},
    description: "",
    product_is_active: false,
    product_status: 0,
    related_seller_id: sellerOwnerData.uid,
    commercial_partner_id: sellerOwnerData.business_id,
    commercial_partner_label: sellerOwnerData?.business_name,
    categories: [],
    categories_ids: [],
    commercial_partner_price_without_VAT: null,
    commercial_partner_VAT_value: null,
    commercial_partner_price_with_VAT: null,
    commercial_partner_price_with_VAT_with_service_fee: null,
    VAT_percentage: null,
    suggested_retail_price: null,
    show_product_to_other_sellers: false,
    product_name_for_search: "",
    product_dimensions: {
      height: 1,
      weight: 1,
      width: 1,
      depth: 1,
    },
    related_shipping_address_id: null,
    service_fee: 100,
  };

  const variationDefault = {
    variation_label: "DEFAULT",
    images: [],
    NewImages: [],
    is_default: true,
    is_active: true,
    sku: generateRandomId(),
    variation_id: generateRandomId(),
  };

  const fileInputRef = useRef(null);
  const [productValues, setproductValues] = useState(initialState);
  // eslint-disable-next-line no-unused-vars
  const [hasVariationsDissabled, setHasVariationsDissabled] = useState(null);
  const [deletedImagesFromVariations, setDeletedImagesFromVariations] = useState([]);
  const MAX_IMAGE_SIZE = 1 * 1024 * 1024; // 1MB
  const [shippingAddress, setShippingAddress] = useState({});
  const [selectedCategory, setSelectedCategory] = useState();

  const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);
  const [categoryProducts, setCategoryProducts] = useState([]);

  const handleToggleProductIsActive = () => {
    // Actualiza el estado utilizando el anterior valor de product_is_active
    setproductValues((prevValues) => {
      const updatedValues = {
          ...prevValues,
          product_is_active: !prevValues.product_is_active,
        };
        saveObjectToLocalStorage("beforeProductState", {...updatedValues});
        return updatedValues
      });
  };

  const handleToggleShowProductToOtherSellers = () => {
    // Actualiza el estado utilizando el anterior valor de product_is_active
    setproductValues((prevValues) => {
        const updatedValues = {
            ...prevValues,
            show_product_to_other_sellers: !prevValues.show_product_to_other_sellers,
        };
        // Guardar en localStorage con los valores actualizados
        saveObjectToLocalStorage("beforeProductState", {...updatedValues});
        // Retorna los valores actualizados para setproductValues
        return updatedValues;
    });
};


  const handleInputSelectChange = (fieldName, e) => {
    let value = e;
    // Guardar los valores previos antes de la actualización
    if (fieldName === "related_shipping_address_label") {
      setShippingAddress(e);
      setproductValues((prevValues) => ({
        ...prevValues,
        related_shipping_address_label: e.address_label,
        related_shipping_address_id: e.id,
      }));
      value = value.address_label;
    } else if (fieldName === "category_label") {
      setSelectedCategory(e);
      setproductValues((prevValues) => ({
        ...prevValues,
        category_label: e.category_label,
        category_id: e.id,
      }));
      value = value.category_label;
    } else if (fieldName === "product_status") {
      setproductValues((prevValues) => ({
        ...prevValues,
        product_status: e.code,
      }));
      value = value.code;
    }
    saveObjectToLocalStorage("beforeProductState", {...productValues,  [fieldName]: value})

  };

  const addArrayToProductValues = (fieldToUpdate, fieldData) => {
    setproductValues((prevData) => ({
      ...prevData,
      [fieldToUpdate]: fieldData,
    }));
  };

  const handleDimensionChange = (fieldName, e) => {
    setproductValues((prevData) => {
        // Actualizamos el estado
        const updatedValues = {
            ...prevData,
            product_dimensions: {
                ...prevData.product_dimensions,
                [fieldName]: e.value,
            },
        };

        // Guardamos en localStorage los valores actualizados
        saveObjectToLocalStorage("beforeProductState", updatedValues);

        return updatedValues;
    });
};


  const validSugestedRetailPrice = () => {
    if ( productValues.suggested_retail_price < productValues.commercial_partner_price_with_VAT) {
      toastFunction(toast, "warn", t("El precio sugerido no puede ser menor al valor comercial"),  "", 4000);
      return false
    }
    return true;
  };

  const processImages = async (requestBody) => {
    const response = await fetch(HANDLEPROCESSIMGS, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody), // Enviar el objeto como cuerpo de la solicitud
    });
    // Obtener el objeto productValues actualizado de la respuesta
    const updatedProductValues = await response.json();
    return updatedProductValues;
  };

  const hasVariationsNewImages = (productValues) => {
    if (!productValues || !productValues.variations) {
      throw new Error('El objeto proporcionado es inválido o no contiene la clave "variations".');
    }

    return productValues.variations.some((variation) => 
      Array.isArray(variation.NewImages) && variation.NewImages.length > 0
    );
  };
  const deduplicateDeletedImages = (deletedImages) => {
    const seenImgIds = new Set();
    const deduplicatedImages = [];

    for (const image of deletedImages) {
      if (!seenImgIds.has(image.img_id)) {
        seenImgIds.add(image.img_id);
        deduplicatedImages.push(image);
      }
    }
    return deduplicatedImages;
  };
  const handleCreate = async (e) => {
    e.preventDefault();
    // eliminamos el id y el nombre de la vinculacion por si se actualiza el producto desde Mis productos vinculados
    productValues.related_synced_id = null;
    productValues.related_synced_name = null;
    productValues.related_seller_id = userData.uid; // Para poder agregarle la propiedad a los productos creados que se estan editando
    let requestBody = {
      productValues: productValues,
      actualUser: userData,
      mode: mode,
    };
    console.log("Antes de cloud function", requestBody)
    setGlobalLoading(true);            
    // const isAllVariationsHaveImages = allVariationsHaveImages();
    const isValidSugestedRetailPrice = validSugestedRetailPrice();
    if ( isValidSugestedRetailPrice ) {
      try {
        if (mode === CREATE) {
          await handleCreateProduct(requestBody);
        } else if (mode === EDIT) {
          await handleEditProduct(requestBody);
        }
      } catch (error) {
        console.error("Error al invocar la Cloud Function:", error);
      }
    }
    setGlobalLoading(false);
  };

  const handleCreateProduct = async (requestBody) => {
    const objectWithProductImagesProcessedToUpdate = await processImages(requestBody);

    if (objectWithProductImagesProcessedToUpdate.ok) {
      removeObjectFromLocalStorage("beforeProductState")
      toastFunction(toast, "success", "Producto Creado con éxito!", "", 4000);
      onClose();
    } else {
      console.error("Error:", objectWithProductImagesProcessedToUpdate.message);
    }
  };

  const handleEditProduct = async (requestBody) => {
    // console.log("Antes de cloud function editProduct -1", requestBody,  hasVariationsNewImages(productValues))

    if (deletedImagesFromVariations.length > 0) {
      const deduplicated = deduplicateDeletedImages(deletedImagesFromVariations);
      await deleteImagesFromStorage(productValues.id, deduplicated);
    }
    if (productValues?.variationDefault?.NewImages) {
      const objectWithProductImagesProcessedToUpdate = await processImages(requestBody);
      if (objectWithProductImagesProcessedToUpdate) {
        setproductValues(objectWithProductImagesProcessedToUpdate.productValues);
        requestBody = {
          productValues: objectWithProductImagesProcessedToUpdate.productValues,
          actualUser: userData,
          mode: mode,
        };
      } else {
        console.error("Error:", objectWithProductImagesProcessedToUpdate.message);
      }
    }
    if (productValues?.variations && hasVariationsNewImages(productValues)) {
      const objectWithProductImagesProcessedToUpdate = await processImages(requestBody);
      if (objectWithProductImagesProcessedToUpdate) {
        setproductValues(objectWithProductImagesProcessedToUpdate.productValues);
        requestBody = {
          productValues: objectWithProductImagesProcessedToUpdate.productValues,
          actualUser: userData,
          mode: mode,
        };
      } else {
        console.error("Error:", responseData.message);
      }
    // console.log("Antes de cloud function EDITpRODUCT -2", requestBody)
      const responseEdit = await fetch(HANDLEEDITEPRODUCT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      console.log("Respuesta cloud function EDIT", responseEdit )
      const responseData = await responseEdit.json();
      if (responseEdit.ok) {
        setproductValues(responseData.productValues);
        toastFunction(toast, "success", "Producto Actualizado con exito", "", 4000);
      } else {
        console.error("Error:", responseData.message);
        toastFunction(toast, "error", "Error editando el producto", responseData.message, 4000);
      }
    }
    // console.log("Antes de cloud function editProduct -3", requestBody)

    const responseEdit = await fetch(HANDLEEDITEPRODUCT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    // console.log("Respuesta cloud fucntion EDITPRODUCT", responseEdit)

    const responseData = await responseEdit.json();
    if (responseEdit.ok) {
      setproductValues(responseData.productValues);
      toastFunction(toast, "success", "Producto Actualizado con éxito!", "", 4000);
      onClose();
    }
  };

  const deleteImagesFromStorage = async (productId, deletedImagesFromVariations) => {
    const deleteImagesForVariation = async (variation_id, imgId) => {
      if (!variation_id || !imgId) {
        console.error("variation_id o imgId no definidos:", {
          variation_id,
          imgId,
        });
        return;
      }
      const imageRef = ref(storage, `products/${productId}/${variation_id}/${imgId}.jpeg`);
      await deleteObject(imageRef);
    };

    for (let img of deletedImagesFromVariations) {
      await deleteImagesForVariation(img.variation_id, img.img_id);
    }
    setDeletedImagesFromVariations([]);
  };

  const handleDeleteProduct = async () => {
    setGlobalLoading(true);
    try {
      // Borrar los archivos del Storage
      // Función para eliminar imágenes basadas en variationId
      const deleteImagesForVariation = async (variation_id) => {
        const variationRef = ref(storage, `products/${productValues.id}/${variation_id}`);
        const listRes = await listAll(variationRef); // Lista todos los objetos en la "carpeta" de la variación

        const deletionPromises = listRes.items.map((itemRef) => deleteObject(itemRef)); // Crea un array de promesas para eliminar cada objeto
        await Promise.all(deletionPromises); // Ejecuta todas las promesas simultáneamente
      };
      // Si hay variations, iterar sobre cada uno y eliminar las imágenes
      if (productValues.product_with_variations) {
        for (let variation of productValues.variations) {
          await deleteImagesForVariation(variation.variation_id);
        }
      }
      // Si hay variationDefault, eliminar sus imágenes
      if (!productValues.product_with_variations) {
        await deleteImagesForVariation(productValues.variationDefault.variation_id);
      }
      // Borrar el documento de Firestore
      const docRef = doc(db, "products", productValues.id);
      console.log("llegue aca en delete");
      await deleteDoc(docRef);
    } catch (error) {
      console.error("Error al borrar el documento:", error);
    }
    setGlobalLoading(false);
    onClose();
  };
  const handleDeletedImagesFromVariations = (deletedImages) => {
    setDeletedImagesFromVariations((prevDeletedImages) => [...prevDeletedImages, ...deletedImages]);
  };

  const addVariationDefault = (selectedProduct) => {
    if (!selectedProduct.product_with_variations) {
      // Verificar que haya variaciones en el array
      if (selectedProduct.variations && selectedProduct.variations.length > 0) {
        const firstVariation = selectedProduct.variations[0];
        // Copiar el objeto selectedProduct y agregar la clave variationDefault
        const productWithDefault = {
          ...selectedProduct,
          variationDefault: firstVariation,
        };
        delete productWithDefault.variations;
        return productWithDefault;
      } else {
        // No hay variaciones en el array
        return selectedProduct;
      }
    } else {
      // El producto ya tiene variaciones, no se realiza ningún cambio
      return selectedProduct;
    }
  };

  const handleVisibleFileReaderInputImg = () => {
    fileInputRef.current.click();
  };

  const handleMainImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    if (file.size > MAX_IMAGE_SIZE) {
        toastFunction(toast, "warn", "La imagen supera el tamaño permitido - 1MB", "", 3000);
        return;
    }

    reader.onloadend = async () => {
        const img = new Image();
        img.src = reader.result;

        img.onload = async () => {
            const canvas = document.createElement("canvas");
            const scaleFactor = 0.8;
            canvas.width = img.width * scaleFactor;
            canvas.height = img.height * scaleFactor;
            
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            // Convertir el canvas a base64
            const base64Image = canvas.toDataURL("image/jpeg", 0.8).split(",")[1];
             
            // Actualizar el estado después de procesar la imagen
            setproductValues({
                ...productValues,
                main_img: {
                  ...productValues.main_img,
                  src: base64Image
                },
            });
        };
    };

    if (file) {
        reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (fieldName, e) => {
    let value;

    if (
      e &&
      fieldName !== "VAT_percentage" &&
      fieldName !== "commercial_partner_price_with_VAT" &&
      fieldName !== "suggested_retail_price"
    ) {
      value = e.target.value;
    } else if (fieldName?.target?.name === "product_with_variations") {
      value = fieldName.target.value;
    } else {
      value = e.value;
    }
    console.log("validacion", fieldName, value)

    if (fieldName === "related_shipping_address_id") {
      const selectedAddress = JSON.parse(value);
      setproductValues((prevData) => ({
        ...prevData,
        related_shipping_address_id: selectedAddress.id,
        related_shipping_address_label: selectedAddress.label,
      }));
    } else if (fieldName === "VAT_percentage") {
      let vatPercentage = value;
      const priceWithVAT = productValues.commercial_partner_price_with_VAT;
      if (vatPercentage <= 0) {
        vatPercentage = 0;
      } 
      if (vatPercentage >= 100) {
        vatPercentage = 99;
      }
      const priceWithoutVAT = priceWithVAT / (1 + vatPercentage / 100);
      const VATValue = priceWithVAT - priceWithoutVAT;

      setproductValues((prevData) => ({
        ...prevData,
        [fieldName]: vatPercentage,
        commercial_partner_VAT_value: VATValue,
        commercial_partner_price_without_VAT: priceWithoutVAT,
      }));
    } else if (fieldName === "commercial_partner_price_with_VAT") {

      const vatPercentage = productValues.VAT_percentage;
      let priceWithVAT = value;
      let VATValue = 0;
      if (priceWithVAT <= 0) {
        priceWithVAT = 0;
      } 
      const priceWithoutVAT = priceWithVAT / (1 + vatPercentage / 100);
      VATValue = priceWithVAT - priceWithoutVAT;

      setproductValues((prevData) => ({
        ...prevData,
        [fieldName]: priceWithVAT,
        commercial_partner_price_without_VAT: priceWithoutVAT,
        commercial_partner_VAT_value: VATValue,
        commercial_partner_price_with_VAT_with_service_fee : priceWithVAT + productValues.service_fee, // Para poder agregarle la propiedad a los productos creados que se estan editando
      }));
    } else if (fieldName?.target?.name === "product_with_variations") {
      if (fieldName.target.value === "false" || fieldName.target.value === false) {
        setproductValues((prevData) => {
          let newData = {
            ...prevData,
            [fieldName.target.name]: false,
            variationDefault: variationDefault,
          }; // Asumiendo que quieres un objeto vacío, ajusta según lo que necesites
          delete newData.variations;
          delete newData.options;
          return newData;
        });
      } else {
        setproductValues((prevData) => {
          let newData = { ...prevData, [fieldName.target.name]: true };
          delete newData.variations;
          delete newData.options;
          delete newData.variationDefault;
          return newData;
        });
      }
    }else if (fieldName === "product_name") {
        setproductValues((prevData) => {
          let newData = { 
            ...prevData, 
            [fieldName]: value,
            product_name_for_search: value.toLowerCase() // Convertir a minúsculas
          };
          return newData;
        });
    } else {
      setproductValues((prevData) => ({
        ...prevData,
        [fieldName]: value,
      }));
    }
    saveObjectToLocalStorage("beforeProductState", {...productValues,  [fieldName]: value})
  };

  const updateCategoryField = (fieldName, newValue) => {
    setproductValues((prevValues) => ({
      ...prevValues,
      [fieldName]: newValue,
      categories_ids: newValue.map((category) => category.id),
    }));
    saveObjectToLocalStorage("beforeProductState", {...productValues,  [fieldName]: newValue, categories_ids: newValue.map((category) => category.id)})
  };
  
  useEffect(() => {
    let filteredCategories = []; 
    let matchShippingAddress = null;

    if (categoryProductsData) {
      // Filtro las categorias para depurar la opcion "todas las categorias"
      filteredCategories = categoryProductsData.filter((category) => category.id !== "ALLCATEGORIES");
      setCategoryProducts(filteredCategories);
    }
    if (selectedProduct) {
      // Si el usuario es insider buscamos el Vendedor propietario de las direcciones
      if (isInsider) {
        const q = query(collection(db, 'seller_users'),
          where('business_id', '==', selectedProduct?.commercial_partner_id),
          where("isAdmin", "==", true),
         );
    // Obtener los documentos de Firestore del dueño del producto y sus direcciones de despacho
      getDocumentsByQuery(q)
          .then((data) => {
            // Actualizar la direccion del formulario con el objeto
            matchShippingAddress = data[0]?.shipping_address?.find((address) => {
              return address.id === selectedProduct.related_shipping_address_id;
            });
            console.log("data",data, selectedProduct?.commercial_partner_id)
            setShippingAddress(matchShippingAddress);
            setOwnerShippingAddress(data[0]?.shipping_address);
            })
          .catch((error) => {
            console.error("Error al obtener datos:", error);
          });
      } else {
        matchShippingAddress = userOwnerData?.shipping_address?.find((address) => {
          return address.id === selectedProduct.related_shipping_address_id;
        });
        setShippingAddress(matchShippingAddress);
      }
      const matchedCategories = filteredCategories.filter(obj => 
        selectedProduct?.categories_ids?.includes(obj.id)
      );
      
      // Actualizar el estado con las categorías que coinciden
      setSelectedCategory(matchedCategories);
    }
    if (mode === CREATE) {
      const beforeProductState = getObjectFromLocalStorage("beforeProductState");
      const matchedCategories = filteredCategories.filter(obj => 
        beforeProductState?.categories_ids?.includes(obj.id)
      );
      matchShippingAddress = userOwnerData?.shipping_address?.find((address) => {
        return address.id === beforeProductState?.related_shipping_address_id;
      });
      if (beforeProductState) {
        toastFunction(toast, "info", "Tienes un producto por crear!", "", 3000);
        setproductValues(beforeProductState);
        setSelectedCategory(matchedCategories);
        setShippingAddress(matchShippingAddress);
      } else {
        setproductValues(initialState);
      }
      setGlobalLoading(false);
      setHasVariationsDissabled(false);
      setDeletedImagesFromVariations([]);
    } else if (mode === EDIT) {
      if (!selectedProduct.product_with_variations) {
        const productWithDefault = addVariationDefault(selectedProduct);
        setproductValues(productWithDefault);
      } else {
        setproductValues(selectedProduct);
      }
      setHasVariationsDissabled(true);
      setGlobalLoading(false);
      setDeletedImagesFromVariations([]);
    }
  }, [mode, selectedProduct, userData, userSellerOwnerData]);

  return (
    <>
      <form onSubmit={handleCreate}>
        <>
          {productBasicInfo(
            productValues,
            handleInputChange,
            shippingAddress,
            handleToggleProductIsActive,
            mode,
            handleInputSelectChange,
            handleVisibleFileReaderInputImg,
            handleMainImageChange,
            fileInputRef,
            sellerOwnerData,
            categoryProducts,
            selectedCategory,
            handleToggleShowProductToOtherSellers,
            setSelectedCategory,
            updateCategoryField,
            t,
            globalLoading,
            ownerShippingAddress
          )}
          {productVariations(
            productValues,
            handleInputChange,
            addArrayToProductValues,
            mode,
            hasVariationsDissabled,
            handleDeletedImagesFromVariations,
            globalLoading
          )}
          {productDimensionsInputs(productValues, handleDimensionChange, globalLoading)}
          <div className="grid gap-4">
            <span>
              <MainButton
                type={"onSubmit"}
                label={mode === EDIT ? t("updateProduct") : t("createProduct")}
                loading={globalLoading}
                autoFocus
              />
            </span>
            {mode === EDIT && (
              <span>
                <DeleteButton
                  type={"button"}
                  label={t("deleteProduct")}
                  loading={globalLoading}
                  onClick={() => setVisibleConfirmDialog(true)}
                  disabled={globalLoading}
                />
              </span>
            )}
          </div>
          <div>
            <CustomConfirmDialog
              visible={visibleConfirmDialog}
              onHide={() => setVisibleConfirmDialog(false)}
              header={t("confirmDialog-deleteProduct.header")}
              message={t("confirmDialog-deleteProduct.message")}
              icon="pi pi-question-circle"
              accept={handleDeleteProduct}
              reject={() => setVisibleConfirmDialog(false)}
              acceptLabel={t("confirmDialog-deleteProduct.acceptLabel")}
              rejectLabel={t("confirmDialog-deleteProduct.rejectLabel")}
            />
          </div>
        </>
      <Toast ref={toast} />

      </form>
    </>
  );
};

ProductForm.propTypes = {
  mode: PropTypes.oneOf([CREATE, EDIT]),
  selectedProduct: PropTypes.shape({
    id: PropTypes.string,
    product_name: PropTypes.string,
    product_is_active: PropTypes.bool,
    product_status: PropTypes.number,
    categories: PropTypes.array,
    product_dimensions: PropTypes.shape({
      weigth_unit: PropTypes.number,
      size_unit: PropTypes.number,
      weight: PropTypes.number,
      width: PropTypes.number,
      depth: PropTypes.number,
      height: PropTypes.number,
    }),
    commercial_partner_id: PropTypes.string,
    category_id: PropTypes.string,
    category_label: PropTypes.string,
    commercial_partner_price_without_VAT: PropTypes.number,
    commercial_partner_VAT_value: PropTypes.number,
    product_with_variations: PropTypes.bool,
    VAT_percentage: PropTypes.number,
    values: PropTypes.arrayOf(PropTypes.object),
    variations: PropTypes.arrayOf(PropTypes.object),
    related_shipping_address_id: PropTypes.string,
    related_shipping_address_label: PropTypes.string, // Agregado
  }),
  onProductDeleted: PropTypes.func,
  actualUser: PropTypes.shape({
    associated_assets: PropTypes.shape({
      id: PropTypes.string,
      asset_id: PropTypes.string,
      asset_label: PropTypes.string,
    }),
  }),
  categoryData: PropTypes.arrayOf(
    PropTypes.shape({
      // Asegúrate de que esto refleje la estructura correcta
      id: PropTypes.string,
      category_label: PropTypes.string,
    })
  ),
  toast: PropTypes.object,
  onClose: PropTypes.func,
  categoryProductsData: PropTypes.arrayOf(
    PropTypes.shape({
      // Asegúrate de que esto refleje la estructura correcta
      id: PropTypes.string,
      brand_label: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};

export default ProductForm;

function productVariations(
  productValues,
  handleInputChange,
  addArrayToProductValues,
  mode,
  hasVariationsDissabled,
  handleDeletedImagesFromVariations,
  globalLoading
) {
  return (
    <div className="p-4  rounded-xl shadow-lg mb-4 border">
      <label className="justify-start flex mt-2  text-sm font-medium mb-2">¿El Producto contiene Variaciones?</label>
      <div className="flex items-center justify-center mb-2">
      <input
          type="radio"
          className={`border mr-2  ${hasVariationsDissabled ? "opacity-50" : ""}`}
          name="product_with_variations"
          value={true}
          checked={productValues?.product_with_variations === true}
          onChange={(e) => {
            const updatedValue = e.target.value === "true";
            
            // Llama a handleInputChange para actualizar el estado
            handleInputChange({
              target: {
                name: e.target.name,
                value: updatedValue,
              },
            });

            // Guarda el nuevo valor en localStorage
            saveObjectToLocalStorage("beforeProductState", {
              ...productValues,
              product_with_variations: updatedValue,
            });
          }}
          disabled={hasVariationsDissabled || globalLoading}
          required
        />

        <label className="mr-4">Sí</label>
        <input
            type="radio"
            className={`border mr-2  ${hasVariationsDissabled ? "opacity-50" : ""}`}
            name="product_with_variations"
            value={false}
            checked={productValues?.product_with_variations === false}
            onChange={(e) => {
              const updatedValue = e.target.value === "true";

              // Corrige la lógica de handleInputChange
              handleInputChange({
                target: {
                  name: e.target.name,
                  value: updatedValue,  // Usa updatedValue en lugar de e.target === "true"
                },
              });

              // Guarda en localStorage el nuevo estado actualizado
              saveObjectToLocalStorage("beforeProductState", {
                ...productValues,
                product_with_variations: updatedValue,
              });
            }}
            disabled={globalLoading}
            required
          />
          <label>No</label>

      </div>
      <main>
        {productValues?.product_with_variations === true ? (
          <div className="">
            <ProductVariations
              productValues={productValues}
              productVariations={productValues.variations}
              productOptions={productValues.options}
              addArrayToProductValues={addArrayToProductValues}
              mode={mode}
              handleDeletedImagesFromVariations={handleDeletedImagesFromVariations}
              globalLoading={globalLoading}

            />
          </div>
        ) : null}
        {productValues?.product_with_variations === false ? (
          <div className="">
            <ProductVariations
              productValues={productValues}
              productVariationDefault={productValues.variationDefault}
              addArrayToProductValues={addArrayToProductValues}
              mode={mode}
              handleDeletedImagesFromVariations={handleDeletedImagesFromVariations}
              globalLoading={globalLoading}
            />
          </div>
        ) : null}
      </main>
    </div>
  );
}
function imgWrapperUser(handleVisibleFileReaderInputImg, userFormValues, handleMainImageChange, fileInputRef, globalLoading) {
  return (
    <div className="">
      <div
        className="bg-slate-100 rounded-full h-28 w-28 mb-6 mt-8 relative overflow-hidden"
        onClick={handleVisibleFileReaderInputImg}
        style={{
          cursor: "pointer",
        }}
      >
        {!userFormValues?.product_main_img_src && (
          <button className="opacity-40 pointer mt-6 ml-1" type="button"
          disabled={globalLoading}
          >
            {" "}
            <AttachImg />
          </button>
        )}
        {userFormValues?.main_img && (
          <div className="absolute inset-0">
          <img
            src={userFormValues.main_img.src ? `data:image/jpeg;base64,${userFormValues.main_img.src}` : userFormValues.main_img.url}
            className="w-full h-full object-cover rounded-full"
            style={{ pointerEvents: "none" }}
            disabled={globalLoading}
          />
          </div>
        )}

        <div className="my-4 bg-red-500">
          <input type="file" accept="image/*" onChange={handleMainImageChange} style={{ display: "none" }} ref={fileInputRef} />
        </div>
      </div>
    </div>
  );
}
function productBasicInfo(
  productValues,
  handleInputChange,
  shippingAddress,
  handleToggleProductIsActive,
  mode,
  handleInputSelectChange,
  handleVisibleFileReaderInputImg,
  handleMainImageChange,
  fileInputRef,
  sellerOwnerData,
  categoryProducts,
  selectedCategory,
  handleToggleShowProductToOtherSellers,
  setSelectedCategory,
  updateCategoryField,
  t,
  globalLoading,
  ownerShippingAddress
) {
  return (
    <div>
      <label className="justify-start flex mt-2  text-sm font-medium mb-2">Imagen principal del producto</label>
      <div className="flex justify-center">
        {imgWrapperUser(handleVisibleFileReaderInputImg, productValues, handleMainImageChange, fileInputRef, mode, globalLoading)}
      </div>

      <CustomInputText
        floatLabel={true}
        name="product_name"
        type="text"
        value={productValues?.product_name}
        placeholder={t("input-placeholders.nameProduct")}
        onChange={(e) => handleInputChange("product_name", e)}
        required={true}
        disabled={globalLoading}
      />
      <label className="justify-start flex mt-2  text-sm font-medium mb-2">Categorias</label>
      <CustomMultiSelect
        floatLabel={true}
        optionLabel={"category_label"}
        options={categoryProducts}
        value={selectedCategory}
        onValue={setSelectedCategory}
        placeholder={t("input-placeholders.categoryProduct")}
        updateField={updateCategoryField}
        fieldNameToUpdate={"categories"}
        disabled={globalLoading}
      />
      <CustomTextArea
        value={productValues?.description}
        placeholder={t("input-placeholders.description")}
        onChange={(e) => handleInputChange("description", e)}
        floatLabel={true}
        required={false}
        />
      <div className="grid">
        <label className="flex justify-center mt-6  text-sm font-medium mb-2">
          ¿Quieres que este producto este visible para otros vendedores?
        </label>
        <span className="flex justify-center">
          <CustomInputSwitch
            checked={productValues?.show_product_to_other_sellers}
            name={"show_product_to_other_sellers"}
            onChange={handleToggleShowProductToOtherSellers}
            disabled={globalLoading }
          />
        </span>
      </div>
      <div className="grid">
        <label className="flex justify-center mt-2  text-sm font-medium mb-2">¿El Producto está Activo?</label>
        <span className="flex justify-center">
          <CustomInputSwitch
            checked={productValues?.product_is_active}
            name={"product_is_active"}
            onChange={handleToggleProductIsActive}
            disabled={globalLoading}
          />
        </span>
      </div>

      <CustomInputNumber
        floatLabel={true}
        className="p-2 m-0 w-full"
        inputId="currency-cop"
        mode="currency"
        currency="COP"
        locale="es-CO"
        name="commercial_partner_price_with_VAT"
        value={productValues?.commercial_partner_price_with_VAT}
        onChange={(e) => handleInputChange("commercial_partner_price_with_VAT", e)}
        placeholder={"Precio comercial con IVA"}
        disabled={globalLoading}
        required={true}
      />
      <CustomInputNumber
        floatLabel={true}
        className="p-2 m-0 w-full"
        inputId="percent"
        name="VAT_percentage"
        value={productValues?.VAT_percentage}
        onChange={(e) => handleInputChange("VAT_percentage", e)}
        suffix="%"
        max={99}
        placeholder={"Porcentaje de IVA"}
        disabled={globalLoading}
        required={true}
      />
      <CustomInputNumber
        floatLabel={true}
        className="p-2 m-0 w-full"
        inputId="currency-cop"
        mode="currency"
        currency="COP"
        locale="es-CO"
        name="commercial_partner_VAT_value"
        value={productValues?.commercial_partner_VAT_value}
        // onChange={(e) => handleInputChange("commercial_partner_VAT_value", e)}
        placeholder={"Valor de IVA"}
        disabled={true}
      />
      <CustomInputNumber
        floatLabel={true}
        className="p-2 m-0 w-full"
        inputId="currency-cop"
        mode="currency"
        currency="COP"
        locale="es-CO"
        name="commercial_partner_price_without_VAT"
        value={productValues?.commercial_partner_price_without_VAT}
        onChange={(e) => handleInputChange("commercial_partner_price_without_VAT", e)}
        placeholder={"Valor del Producto sin IVA"}
        disabled={true}
      />

      <CustomInputNumber
        floatLabel={true}
        className="p-2 m-0 w-full"
        inputId="currency-cop"
        mode="currency"
        currency="COP"
        locale="es-CO"
        name="commercial_partner_price_with_VAT_with_service_fee"
        value={productValues?.commercial_partner_price_with_VAT_with_service_fee}
        placeholder={"Valor con tarifa Supplaii"}
        disabled={true}
      />

      <CustomInputNumber
        floatLabel={true}
        className="p-2 m-0 w-full"
        inputId="currency-cop"
        mode="currency"
        currency="COP"
        locale="es-CO"
        name="suggested_retail_price"
        value={productValues?.suggested_retail_price}
        onChange={(e) => handleInputChange("suggested_retail_price", e)}
        placeholder={" Valor Sugerido de Reventa"}
        disabled={globalLoading}
        required={true}
      />

      <div className="">
        <CustomSelect
          required={true}
          floatLabel={true}
          optionLabel="address_label"
          // options={sellerOwnerData?.shipping_address}
          options={ownerShippingAddress}
          valueTemplate=""
          itemTemplate=""
          value={shippingAddress}
          placeholder="Direccion de despacho"
          onChange={(e) => handleInputSelectChange("related_shipping_address_label", e.target.value)}
          disabled={globalLoading }
        />
      </div>
    </div>
  );
}
function productDimensionsInputs(productValues, handleDimensionChange, globalLoading) {
  return (
    <div className="p-4 rounded-xl shadow-lg border mb-8">
      <label className="justify-start flex mt-2  text-xl font-semibold mb-6">Dimensiones Del Producto</label>
      <CustomInputNumber
        floatLabel={true}
        className="p-2 m-0 w-full"
        value={productValues?.product_dimensions?.weight}
        onChange={(e) => handleDimensionChange("weight", e)}
        min={1}
        placeholder={"Peso ( gramos )"}
        required={true}
        disabled={globalLoading}
      />

      <CustomInputNumber
        floatLabel={true}
        className="p-2 m-0 w-full"
        value={productValues?.product_dimensions?.width}
        onChange={(e) => handleDimensionChange("width", e)}
        min={1}
        placeholder={"Ancho ( centimetros )"}
        required={true}
        disabled={globalLoading}
      />
      <CustomInputNumber
        floatLabel={true}
        className="p-2 m-0 w-full"
        value={productValues?.product_dimensions?.depth}
        onChange={(e) => handleDimensionChange("depth", e)}
        min={1}
        placeholder={"Profundidad ( Centimetros )"}
        required={true}
        disabled={globalLoading}
      />
      <CustomInputNumber
        floatLabel={true}
        className="p-2 m-0 w-full"
        value={productValues?.product_dimensions?.height}
        onChange={(e) => handleDimensionChange("height", e)}
        min={1}
        placeholder={"Altura ( Centimetros )"}
        required={true}
        disabled={globalLoading}
      />
    </div>
  );
}
