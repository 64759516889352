import './i18n/i18n';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GlobalProvider } from './contexts/GlobalContext/GlobalContext';
import { AuthProvider } from './contexts/Authcontext/Authcontext';
import GlobalLayout from './layouts/GlobalLayout/GlobalLayout';
import PrivateRoute from './customRoutes/privateRoute';
import GuestRoute from './customRoutes/GuestRoute';
import Landing from './pages/Landing/Landing';
import Signup from './pages/Signup/Signup';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
// import Draws from './pages/Draws/Draws';
import Wallet from './pages/Wallet/Wallet';
import ShippingWallet from './pages/ShippingWallet/ShippingWallet.jsx';

import Stores from './pages/Stores/Stores';
import MyShopifyStores from './pages/MyShopifyStores/MyShopifyStores.jsx';
import MyShoppexStores from './pages/MyShoppexStores/MyShoppexStores';
import Account from './pages/Account/Account';
import Promotions from './pages/Promotions/Promotions';
import Products from './pages/Products/Products';
import MyVinculatedProducts from './pages/MyVinculatedProducts/MyVinculatedProducts';
import NewMyVinculatedProducts from './pages/NewMyVinculatedProducts/NewMyVinculatedProducts.jsx';

import MyOrders from './pages/MyOrders/MyOrders';
import Withdrawals from './pages/Withdrawals/Withdrawals.jsx';
import ShippingNews from './pages/ShippingNews/ShippingNews.jsx';

import MyCustomers from './pages/MyCustomers/MyCustomers.jsx';
import MyCreatedProducts from './pages/MyCreatedProducts/MyCreatedProducts.jsx';
import { LoaderContextProvider } from './contexts/LoaderContext/LoaderContext';
// import BlankPage from './pages/BlankPage/BlankPage';
import InvisibleCatpchaWrapper from './services/auth/InvisibleCatpchaWrapper/InvisibleCatpchaWrapper';
import MyUsers from './pages/MyUsers/MyUsers';
import Users from './pages/Users/Users';
import ConsolidatedOrders from './pages/ConsolidatedOrders/ConsolidatedOrders';
import Subscriptions from './pages/Subscriptions/Subscriptions.jsx';

import MyCatalog from './pages/MyCatalog/MyCatalog.jsx';
import Catalog from './pages/Catalog/Catalog.jsx';
import WhatsAppCRMpage from './pages/WhatsAppCRMpage/WhatsAppCRMpage.jsx';

import CatalogProductPage from './pages/CatalogProductPage/CatalogProductPage.jsx';
import CatalogCreatorSuccess from './pages/CatalogCreatorSuccess/CatalogCreatorSuccess.jsx';

import Inventary from './pages/Inventary/Inventary.jsx';

import ThankyouPage from './pages/ThankyouPage/ThankyouPage.jsx';

import { useEffect } from 'react';
import { initializeFacebookPixel } from './PIXELs/PIXELs/Meta/pixelFB.js';
import { initializeTiktokPixel } from './PIXELs/PIXELs/Tiktok/pixelTT.js';
import { pageView } from './PIXELs/PIXELs/Events/Events.js';
import Privacy from './Legal/Privacy.jsx';
import TyC from './Legal/TyC.jsx';



function App() {

  useEffect(()=>{
    const initializePixels = async () => {
      try {
        await initializeFacebookPixel("1223637668808634");
        await initializeTiktokPixel("")
        pageView()
      } catch (error) {
        console.error("Error 'initializePixels'")
      }
    };
    initializePixels()
  }, [])

  return (
    <Router>
      <LoaderContextProvider>
        <AuthProvider>
          <GlobalProvider>
              <GlobalLayout>
                  <Routes>
                    <Route path="/" exact element={<Landing />}  />
                    <Route path="/signup" element={<GuestRoute><InvisibleCatpchaWrapper><Signup /></InvisibleCatpchaWrapper></GuestRoute>} />
                    <Route path="/login" element={<GuestRoute><InvisibleCatpchaWrapper><Login /></InvisibleCatpchaWrapper></GuestRoute>} />
                    <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
                    <Route path="/myUsers" element={<PrivateRoute><MyUsers /></PrivateRoute>} />
                    <Route path="/users" element={<PrivateRoute><Users /></PrivateRoute>} />
                    <Route path="/stores" element={<PrivateRoute><Stores /></PrivateRoute>} />
                    <Route path="/myStores" element={<PrivateRoute><MyShopifyStores /></PrivateRoute>} />
                    <Route path="/myShoppexStores"   element={<PrivateRoute><MyShoppexStores /></PrivateRoute>} />
                    <Route path="/account" element={<PrivateRoute><Account /></PrivateRoute>} />
                    <Route path="/promotions" element={<PrivateRoute><Promotions /></PrivateRoute>} />
                    <Route path="/products" element={<PrivateRoute><Products /></PrivateRoute>} />

                    <Route path="/myVinculatedProducts" element={<PrivateRoute><MyVinculatedProducts /></PrivateRoute>} />
                    
                    <Route path="/myCreatedProducts" element={<PrivateRoute><MyCreatedProducts /></PrivateRoute>} />
                    <Route path="/myOrders" element={<PrivateRoute><MyOrders/></PrivateRoute>} />
                    <Route path="/myCustomers" element={<PrivateRoute><MyCustomers/></PrivateRoute>} />
                    <Route path="/wallet" element={<PrivateRoute><Wallet/></PrivateRoute>} />
                    <Route path="/shippingWallet" element={<PrivateRoute><ShippingWallet/></PrivateRoute>} />
                    <Route path="/withdrawals" element={<PrivateRoute><Withdrawals/></PrivateRoute>} />
                    <Route path="/shippingNews" element={<PrivateRoute><ShippingNews/></PrivateRoute>} />
                    <Route path="/consolidatedOrders" element={<PrivateRoute><ConsolidatedOrders/></PrivateRoute>} />
                    <Route path="/subscriptions" element={<PrivateRoute><Subscriptions/></PrivateRoute>} />
                    <Route path="/myCatalog" element={<PrivateRoute><MyCatalog /></PrivateRoute>} /> 

                    <Route path="/catalog" element={<Catalog />} /> {/* Ruta pública para el catalogo*/}
                    <Route path="/catalogProductPage" element={<CatalogProductPage />} /> {/* Ruta pública para ver el producto seleccionado del catalogo*/}
                    <Route path="/thankyouPage" element={<ThankyouPage />} />{/* Ruta pública para el catalogo  Ruta pública para el catalogo*/}

                    <Route path="/whatsAppCRM" element={<WhatsAppCRMpage />} />{/* Ruta pública para el catalogo  Ruta pública para el catalogo*/}

                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/tyc" element={<TyC />} />

                    <Route path="/catalogCreatorSuccess" element={<PrivateRoute><CatalogCreatorSuccess /></PrivateRoute>} />
                    
                    <Route path="/inventary" element={<PrivateRoute><Inventary /></PrivateRoute>} />
                  </Routes>
              </GlobalLayout>
          </GlobalProvider>
        </AuthProvider> 
      </LoaderContextProvider>
    </Router>
  )
}

export default App
