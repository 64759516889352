import  { useRef } from 'react';
import { MdOutlineImage, MdOutlineKeyboardVoice } from 'react-icons/md';
import { TbMapPin } from 'react-icons/tb';
import { BsSend } from 'react-icons/bs';
import styles from './ChatInputMessage.module.css'; // Asegúrate de ajustar la ruta de tu archivo CSS

const ChatInputMessage = () => {
  const fileInputRef = useRef(null);

  // Muestra el selector de archivos cuando se hace clic en el botón de la imagen
  const handleVisibleFileReaderInputImg = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className={styles.chatInputContainer}>
      <div className={styles.chatInput}>
        <input  type="text" placeholder="Escribe " />
        <div className={styles.chatHeaderBtns}>
          <button onClick={handleVisibleFileReaderInputImg}>
            <MdOutlineImage className={styles.icon} />
          </button>
          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            ref={fileInputRef}
          />
          <button>
            <TbMapPin className={styles.icon} />
          </button>
          <button>
            <MdOutlineKeyboardVoice className={styles.icon} />
          </button>
        </div>
      </div>
      <button className={styles.sendButton}>
        <BsSend className={styles.sendIcon} />
      </button>
    </div>
  );
};

export default ChatInputMessage;
