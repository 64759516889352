import { useEffect, useState } from "react";
import ProductGridCard from "../../Components/ProductGridCard/ProductGridCard.jsx";
import CustomModal from "../../Components/CustomModal/CustomModal.jsx";
import DetailProduct from "../../Components/DetailProduct/DetailProduct.jsx";
import useShopifyRequest from "../../hooks/useShopifyRequest.js";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext.jsx";
import CustomSelect from "../../Components/FormComponents/CustomSelect/CustomSelect.jsx";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import { Toast } from "primereact/toast";
import ProductForm from "../../Components/ProductForm/ProductForm.jsx";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn.jsx";
import { BiChevronLeft } from "react-icons/bi";
import { getPaginatedProducts } from "../../utils/getPaginatedProducts.js";
import { collection } from "firebase/firestore";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD.js";
import { db } from "../../firebase/firebase.js";
import toastFunction from "../../utils/toastFunction.js";
// Componente de pagina que muestra los productos vinculados en base a los ids de los productos vinculados con las tiendas de shopify
const MyVinculatedProducts = () => {
  const { setGlobalLoading } = useLoaderContext();
  const { userData, userSellerOwnerData } = useAuthContext();
  const {getDocumentsByQuery,getAllDocumentsFromSubcollection } = useFirebaseCRUD();
  const { toast } = useShopifyRequest();
  const [products, setProducts] = useState([]);
  const [visibleDetailProduct, setVisibleDetailProduct] = useState(false);
  const [selectedProduct, setselectedProduct] = useState({});
  const { t } = useTranslation("Products");
  const [storeSelectedName, setStoreSelectedName] = useState([]);

  const isCoworker = !userData.isAdmin  && userData.related_seller_id && userData.is_coworker

  let userOwnerStores = (isCoworker && userSellerOwnerData) ? userSellerOwnerData.seller_stores : userData.seller_stores
  const sellerOptionsWithGenericOption = [
    ...userOwnerStores,
    { id: 1, name: "Todas las tiendas" }
  ];
  const [visibleProductForm, setVisibleProductForm] = useState(false);

  const [categoryProducts, setCategoryProducts] = useState([]);
  const quantityDocuments = 30;


  // Trae los productos de la coleccion "products" en base a su Id
  const getProductosFromIds = async (productsIds) => {
    try {
      if ( productsIds.length === 0 ) {
        return []
      }
      const body = {
        productsIds: productsIds,
        quantityDocuments,
        type: "onlyProductsSnapshot",
        setProducts,
      };
      const response = await getPaginatedProducts(body)
      if (!response) {
        throw new Error("Error al obtener los productos");
      }
      return response;
    } catch (error) {
      console.error("Error al obtener los productos:", error);
      throw error;
    }
  };
  const getProductCategories = async () => {
    try {
      const q = collection(db, "category_products");
      const categoryProducts = await getDocumentsByQuery(q);
      setCategoryProducts(categoryProducts);
    } catch (error) {
      console.error("Error al obtener los productos:", error);
      setGlobalLoading(false);
      throw error;
      
    }
  };
  // Función para abrir el modal de tiendas y establecer el producto seleccionado
  const openDetailProductModal = (product) => {
    setVisibleDetailProduct(true);
    if (product.id) {
      setselectedProduct(product);
    } else {
      setselectedProduct({});
    }
  };
  // Función para cerrar el modal de tiendas
  const closeDetailProductModal = () => {
    setVisibleDetailProduct(false);
  };
  // Función para cerrar el modal de tiendas
  const closeProductFormModal = () => {
    setVisibleProductForm(false);
  };
  

  console.log("Productos", products)
  // Manejador de eventos cuando se selecciona el nombre de la tienda
  const handleStoreSelectedName = async (selectedStore) => {
    setGlobalLoading(true);
    setStoreSelectedName(selectedStore);
    // La tienda con el id 1 es la opcion "todas las opciones" del select
    try {
      if (selectedStore.id === 1) {
        const productsWithSyncedValues = [];
        console.log("sellerOptionsWithGenericOption", sellerOptionsWithGenericOption) ;
        const products =  await amountAndMatchRelatedProducts(sellerOptionsWithGenericOption);
        console.log("validacion products-1",products )
        productsWithSyncedValues.push(...products)
        setProducts(productsWithSyncedValues);
      } else {
          const products =  await amountAndMatchRelatedProducts([selectedStore]);
          if (products.length > 0) {
            setProducts(products);
          } else {
            // Caso donde no hay productos
            setProducts([]);
            toastFunction(toast, "warn", t("noProductsWithTheseCategory"), "", 3000)
          }
      }
    }  catch (error) {
      console.error("Error al obtener productos:", error);
    }
    setGlobalLoading(false);
  };
  
  // Función para calcular la cantidad de productos y productos sincronizados con Shoppex de todas las tiendas del seller
  const amountAndMatchRelatedProducts = async (sellerStores) => {
    if (!userData || !sellerStores) return [];

    const productsIds = [];
    const syncedProductsIds = [];
    let relatedProducts = [];

    await Promise.all(
        sellerStores.map(async (store) => {
          // SI la plataforma es SHOPPEX 
            if (store?.platform?.code === 1) {
                try {
                    // Obtenemos todos los documentos de la subcolección "related_products"
                    const productsResult = await getAllDocumentsFromSubcollection("stores", store.id, "related_products");
                    
                    // Iteramos sobre los productos y extraemos los `product_id` y `id`
                    productsResult.forEach((product) => {
                        if (product?.product_id && product?.id) {
                            productsIds.push(product.product_id); // Agregamos el product_id al array
                            syncedProductsIds.push(product.id);   // Agregamos el id al array
                            relatedProducts.push(product);        // Acumulamos el producto en relatedProducts
                        }
                    });
                } catch (error) {
                    console.error(`Error obteniendo productos para la tienda ${store.id}:`, error);
                }
            }
        })
    );

    // Obtener los productos a partir de los productIds
    const originalProducts = await getProductosFromIds(productsIds);

    console.log("Productos originales:", originalProducts);
    console.log("Productos relacionados:", relatedProducts);

    // Emparejamos los productos de "products" con los productos de "relatedProducts"
    const mergedProducts = originalProducts.map((product) => {
        // Buscamos en relatedProducts el objeto que tenga el mismo product_id
        const matchingProduct = relatedProducts.find(result => result.product_id === product.id);

        // Si encontramos una coincidencia, combinamos sus propiedades
        return matchingProduct
            ? { ...product, ...matchingProduct, related_synced_name: matchingProduct.custom_title, related_synced_id:matchingProduct.id }
            : product; // Si no hay coincidencia, devolvemos el producto original
    });

    console.log("Productos combinados:", mergedProducts);

    // Devolver los productos combinados
    return mergedProducts.length > 0 ? mergedProducts: [];
  };


  useEffect(() => {
    // Inicializar el estado y cargar los productos al cargar la página o cambiar el usuario
    setGlobalLoading(true);

    const fetchData = async () => {
      await getProductCategories();
      // Crear opciones de vendedor con la opción genérica "Todas las tiendas"
  
      if (userOwnerStores && userOwnerStores.length > 0) {
        try {
          await handleStoreSelectedName({id: 1, name: "Todas las tiendas"});
        } catch (error) {
          console.error("Error al obtener productos:", error);
        }finally {
          setGlobalLoading(false);
        }
      }
    };
    // Llamar a la función fetchData al montar el componente o cuando cambia el usuario
    fetchData();
    setGlobalLoading(false);

  }, [userData, userSellerOwnerData]);

  return (
    <>
    <div style={{ marginBottom: "40px" }}>
        <GoBackButton icon={BiChevronLeft} text={t("labelGoBackBtnMyVinculatedProducts")} />
    </div>
      <div className="mb-12">
        <CustomSelect
          floatLabel={true}
          optionLabel={"name"}
          options={sellerOptionsWithGenericOption}
          value={storeSelectedName}
          placeholder={t("input-placeholders.platform")}
          onChange={(e) => handleStoreSelectedName(e.value)}
          required={true}
        />
      </div>
      <div className="mb-12">
        {products.length > 0 ? (
          <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
            {products.map((product, index) => (
              <ProductGridCard
                key={undefined}
                index={index}
                user_data={userSellerOwnerData || userData}
                product_data={product}
                onClick={() => openDetailProductModal(product)}
              />
            ))}
          </div>
        ) : (
          <marquee>No se encontraron productos</marquee>
        )}
      </div>
      <div>
        <CustomModal
          visible={visibleDetailProduct}
          setVisible={setVisibleDetailProduct}
          object={selectedProduct}
          editMode={t("detailProduct")}
          createMode={t("vinculateProduct")}
          content={
            <DetailProduct
              productData={selectedProduct}
              toast={toast}
              onClose={closeDetailProductModal}
              categoryProductsData={categoryProducts}
              userData={userSellerOwnerData || userData}
            />
          }
          onClose={closeDetailProductModal}
        />
      </div>
      <div>
        <CustomModal
          visible={visibleProductForm}
          setVisible={setVisibleProductForm}
          object={selectedProduct}
          content={
            <ProductForm
              mode="CREATE"
              categoryProductsData={categoryProducts}
              toast={toast}
              onClose={closeProductFormModal}
            />
          }
          onClose={closeProductFormModal}
        />
      </div>
      <Toast ref={toast} />
    </>
  );
};
export default MyVinculatedProducts;
