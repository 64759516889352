import styles from "./WhatsAppCRM.module.css";
import SidebarMenu from "./SidebarMenu/SidebarMenu.jsx";
import ChatWindow from "./ChatWindow/ChatWindow.jsx";
import MessagesListWindow from "./MessagesListWindow/MessagesListWindow.jsx";
import { useState } from "react";
import useResize from "../../hooks/useResize.js";
import { CHATMESSAGE, MESSAGESLIST, SIDEBARMENU } from "./utils/CRMconstants.js";

function WhatsAppCRM() {
  // Estado para manejar en qué vista estamos en mobile
  const [currentView, setCurrentView] = useState(SIDEBARMENU); // 'sidebar', 'messages', 'chat'
  const [isMobile, setIsMobile] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);

  useResize(setIsMobile);
  console.log(isMobile,currentView)

  // Función para manejar la selección en el SidebarMenu
  const handleSidebarSelect = (menuOption) => {
    setCurrentView(menuOption); // Muestra la lista de mensajes
  };

  // Función para manejar la selección en el MessagesListWindow
  const handleMessageSelect = (messageChat) => {
    setSelectedChat(messageChat);
    setCurrentView(CHATMESSAGE); // Muestra la ventana de chat
  };

  return (
    <div className={styles.appContainer}>
      {!isMobile ? (
        // Para escritorio mostramos los 3 componentes al mismo tiempo
        <>
          <SidebarMenu />
          <MessagesListWindow />
          <ChatWindow />
        </>
      ) : (
        // Para mobile mostramos según la vista actual
        <>
          {currentView === SIDEBARMENU && <SidebarMenu onSelectMenuOption={handleSidebarSelect} />}
          {currentView === MESSAGESLIST && <MessagesListWindow onSelectMenuOption={handleSidebarSelect} onMessageSelect={handleMessageSelect} />}
          {currentView === CHATMESSAGE && <ChatWindow onSelectMenuOption={handleSidebarSelect} selectedChat={selectedChat}/>}
        </>
      )}
    </div>
  );
}

export default WhatsAppCRM;
