import styles from "./ChatMessages.module.css"

import { IoCheckmarkSharp, IoCheckmarkDoneSharp } from "react-icons/io5";

export const ChatMessageText = ({ avatarSrc, username, time, messageText, isSent }) => {
    const messageClass = isSent ? styles.sent : styles.received;
    return (
      <div className={`${styles.chatMessageContainer} ${messageClass}`}>
        <img src={avatarSrc} alt={username} className={styles.avatar} />

        <div className={styles.messageContent}>
          <div className={styles.messageHeader}>
            <span className={styles.username}>{username}</span>
            <span className={styles.time}>{time}</span>
          </div>
          <div className={styles.messageText}>
            <p>
              {messageText}
            </p>
            <span className={styles.statusMessageCheck}>
              <IoCheckmarkDoneSharp className={styles.icon} />
            </span>
          </div>
        </div>
      </div>
    );
};

export const ChatVideoMessage = ({ avatarSrc, username, time, videoSrc, isSent }) => {
  const messageClass = isSent ? styles.sent : styles.received;
  return (
    <div className={`${styles.chatMessageContainer} ${messageClass}`}>
      <img src={avatarSrc} alt={username} className={styles.avatar} />
      <div className={styles.messageContent}>
        <div className={styles.messageHeader}>
          <span className={styles.username}>{username}</span>
          <span className={styles.time}>{time}</span>
        </div>
        <div className={styles.messageText}>
          <video controls className={styles.video}>
            <source src={videoSrc} type="video/mp4" />
            Tu navegador no soporta el elemento de video.
          </video>
          <span className={styles.statusMessageCheck}>
            <IoCheckmarkDoneSharp className={styles.icon} />
          </span>
        </div>
      </div>
    </div>
  );
};

export const ChatAudioMessage = ({ avatarSrc, username, time, audioSrc, isSent, messageText }) => {
  const messageClass = isSent ? styles.sent : styles.received;

  return (
    <div className={`${styles.chatMessageContainer} ${messageClass}`}>
      <img src={avatarSrc} alt={username} className={styles.avatar} />


      <div className={styles.messageContent}>
        <div className={styles.messageHeader}>
          <span className={styles.username}>{username}</span>
          <span className={styles.time}>{time}</span>
        </div>
        <div className={styles.messageText}>
        <div className={styles.messageText}>
            <p>
              {messageText}
            </p>  
          
          </div>
        <audio controls className={styles.audioPlayer}>
          <source src={audioSrc} type="audio/mp3" />
            Tu navegador no soporta el elemento de audio.
          </audio>

          <span className={styles.statusMessageCheck}>
              <IoCheckmarkDoneSharp className={styles.icon} />
          </span>
        </div>
      </div>
    </div>
  );
};
