import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect";
import useShopifyRequest from "../../hooks/useShopifyRequest";
import { useTranslation } from "react-i18next";
import MainButton from "../MainButton/MainButton";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import { generateRandomId } from "../../utils/generateRandomId.JS";
import CustomConfirmDialog from "../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog";
import DeleteButton from "../DeleteButton/DeleteButton";
import toastFunction from "../../utils/toastFunction";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db, storage } from "../../firebase/firebase.js";
import CustomInputNumber from "../FormComponents/CustomInputNumber/CustomInputNumber.jsx";
import CustomInputSwitch from "../FormComponents/CustomInputSwitch/CustomInputSwitch.jsx";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText.jsx";
import { deleteObject } from "firebase/storage";
// Componente de formulario que permite encontrar si existe una vinculacion o vincular un producto de una tienda de shopify
const VinculateProductForm = ({ productData, userData, toast, onClose,  }) => {
  const { t } = useTranslation("Products");
  const { fetchShopifyProductConnection } = useShopifyRequest();
  const {
    getDocumentsByQuery,
    getDocumentById,
    updateDocument,
    getAllDocumentsFromSubcollection,
    updateSubcollectionDocument,
    createSubcollectionDocument,
    deleteSubcollectionDocument,
  } = useFirebaseCRUD();

  const [storeSelect, setStoreSelect] = useState([]);
  const [productSelect, setProductSelect] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const sellerStores = userData?.seller_stores ? userData?.seller_stores.filter((store) => store.platform.code !== 3) :  []
  const [shoppexStoreData, setShoppexStoreData] = useState();
  const [productHasVinculated, setProductHasVinculated] = useState(false);
  const [disabledProductAndStoreSelect, setDisabledProductAndStoreSelect] = useState(false);
  const { globalLoading, setGlobalLoading } = useLoaderContext();
  const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);
  

  const relatedSyncedShoppexProductInitialValues = {
      id: generateRandomId(),
      product_id: productData.id, // Id del producto ( ide del documento del producto en la coleccion "products")
      // TODO: Hereda el nombre para facilitar la vinculacion  
      custom_title: productData?.product_name|| "", // Titulo personalizado del producto
      premium_service_fee: 0, // Tarifa de servicio premium (Usado si la tienda is_a_buying_club: true )
      basic_service_fee: 0, // Tarifa de servicio basica (seria la ganancia del seller)
      with_fixed_price: false, // Si el monto total que pagará el consumidor final por el producto es fijo (Significa que si el precio de proveedor cambia, el precio en la tienda no se verá afectado, si es false, entonces el precio en tienda se actualiza automáticamente si cambia el precio del proveedor ya que se sumará el supplier_price + basic_service_fee
      is_active: true, // Indicador de si el producto esta activo
      suggested_retail_price: productData?.suggested_retail_price||  0, // Precio sugerido de venta, se guarda para emparejar el producto correctamente
      fixed_price: productData?.suggested_retail_price, // Monto FIJO total que pagara el consumidor final. Usado si with_fixed_price: true)
      product_cost: productData?.commercial_partner_price_with_VAT_with_service_fee,
      comparative_price: 0, // Precio de comparacion
  };

  const [ relatedSyncedShoppexProductValues, setRelatedSyncedShoppexProductValues ] = useState(relatedSyncedShoppexProductInitialValues)
  // console.log("relatedSyncedShoppexProductValues", relatedSyncedShoppexProductValues, productData, productData?.commercial_partner_price_with_VAT + productData?.service_fee)
  const [ premiumServiceFeeValue, setPremiumServiceFeeValue ] = useState();
  const [ comparativePriceValue, setComparativePriceValue ] = useState();

  const isFirstRender = useRef(true); 

  // Función para encontrar el producto vinculado en los documentos de tienda
  const findStoreAndVinculatedProductInShoppexStore = async (relatedSyncedId, sellerStores) => {
    console.log("validacion", sellerStores);
    const shoppexSellerStores = sellerStores.filter((store) => store.platform.code === 1);
    
    // Si no hay tiendas, devolvemos un objeto vacío
    if (shoppexSellerStores.length === 0) {
      return {
        vinculatedStore: null,
        vinculatedProduct: null,
      };
    }
    
    // Función auxiliar para buscar un productId en una lista de strings (syncedList)
    const findSyncedProductInDocumentStoreData = async (storeId, relatedSyncedId) => {
      const relatedStoreProductsData = await getAllDocumentsFromSubcollection("stores", storeId, "related_products");
      const syncedProductFound = relatedStoreProductsData.find((relatedProduct) => relatedProduct.id === relatedSyncedId);
      console.log("syncedProductFound", syncedProductFound)
      return syncedProductFound || null; // Retorna el producto encontrado o null
    };

    // Itera sobre cada tienda y verifica si el productId se encuentra en alguna de ellas
    for (const vinculatedStore of shoppexSellerStores) {
      const vinculatedProduct = await findSyncedProductInDocumentStoreData(vinculatedStore.id, relatedSyncedId); // Esperamos la promesa
      if (vinculatedProduct) {
        return {
          vinculatedStore,
          vinculatedProduct,
        };
      }
    }

    // Retornamos un objeto con propiedades null si no se encontró el producto
    return {
      vinculatedStore: null,
      vinculatedProduct: null,
    };
  };

  const handleDeleteVinculation = async () => {
    setGlobalLoading(true)
     if (storeSelect?.platform?.code === 2 && productSelect.length===0) {
      toastFunction( toast, "info", "Para eliminar una vinculacion con Shopify es necesario seleccionar el producto", "", 3000);
        return
    }

    if (storeSelect?.platform?.code === 1) { // SI la tienda seleccionada es de shoppex hay que eliminar el objeto relacion de related_products en la tienda shoppex
      // Eliminamos el fixed price al igual que los objetos de la vinculacion
        const newFixedprices = {
          ...shoppexStoreData.fixed_prices,
        }
        delete newFixedprices[relatedSyncedShoppexProductValues.product_id]
       
        if (relatedSyncedShoppexProductValues){
          for (const section of relatedSyncedShoppexProductValues.sections) {
            if (section.media_path) {
              await deleteObject(storage, section.media_path);
            }
          }
        }
        await deleteSubcollectionDocument("stores", shoppexStoreData.id, "related_products", relatedSyncedShoppexProductValues.id);
        await updateDocument("stores", shoppexStoreData.id, {fixed_prices: newFixedprices});

        toastFunction( toast, "success", "Vinculacion eliminada con exito", "", 3000);
    }

    setTimeout(() => {
      onClose();
      setGlobalLoading(false)      
    }, 500);
  };

  const productHasInProductInShoppexStore = async (storeId, productId) => {
    try {
      // Construir la referencia a la subcolección "related_products" dentro de "stores"
      const relatedProductsRef = collection(db, "stores", storeId, "related_products");
      
      // Crear una consulta para buscar el documento donde "product_id" sea igual al productId
      const q = query(relatedProductsRef, where("product_id", "==", productId));
      
      // Ejecutar la consulta
      const querySnapshot = await getDocs(q);
  
      // Si hay algún documento que coincida, el producto existe
      return !querySnapshot.empty;
    } catch (error) {
      console.error("Error al verificar si el producto existe en la tienda:", error);
      return false;
    }
  };
  
  // Manejar la selección de una tienda
  const handleStoreSelect = async (store) => {
    setStoreSelect(store);
    const hasIdInShoppexStore = await productHasInProductInShoppexStore(store.id, productData.id)
    setGlobalLoading(true);
    switch (store?.platform?.code) {
      case 1:       // Si la plataforma es shoppex, al elegir el producto calculamos y actualizamoslos valores en base al producto seleccionado
        if (hasIdInShoppexStore) {
          setProductHasVinculated(true);
          toastFunction(toast, "warn", `${t("toast-summary.theProduct")}${productData.product_name} ${t("toast-summary.productIsVinculated")}`,  "", 4000);
        } else {
          const shoppexStoreData = await getDocumentById("stores", store.id)
          setShoppexStoreData(shoppexStoreData);
          setProductHasVinculated(false);
          toastFunction(toast, "success", `${t("toast-summary.theProduct")}${productData.product_name} ${t("toast-summary.productIsNotVinculated")}`,  "", 4000);
          setRelatedSyncedShoppexProductValues((prevValues) => ({
            ...prevValues,
            product_cost: productData?.commercial_partner_price_with_VAT_with_service_fee,
            fixed_price: productData?.suggested_retail_price,
            basic_service_fee: productData.suggested_retail_price - (productData?.commercial_partner_price_with_VAT_with_service_fee),
            // se agrega el precio sugerido para poder emparejar los productDataos despues
            suggested_retail_price: productData.suggested_retail_price,
          }));
        }
        break;
      case 2:
        await fetchShopifyProductsData(store);
        break;
      default:
        console.log("Plataforma no especificada");
        break;
    }
    setGlobalLoading(false);
  };

  const fetchShoppexSellerProducts = async (businessId) => {
    const queryRef = query(collection(db, "products"), where("commercial_partner_id", "==", businessId));
    const resultFetchShoppex =  await getDocumentsByQuery(queryRef);
    const extractedProductShoppexProperties = resultFetchShoppex.map((product) => {
      const { product_name, id, commercial_partner_price_with_VAT, suggested_retail_price } = product;
      return { name: product_name, id: id, commercial_partner_price_with_VAT, suggested_retail_price };
    });

    // Si se selecciona un producto del cual el usario es dueño no podra vincularlo con el mismo producto
     if (userData?.business_id === productData?.commercial_partner_id) {
       const filteredExtractedDataWhithoutSelectedShoppexProduct = extractedProductShoppexProperties?.filter((shoppexProduct) => {
         return shoppexProduct?.id !== productData?.id
       })
       setProductsData(filteredExtractedDataWhithoutSelectedShoppexProduct);
     }else {
       setProductsData(extractedProductShoppexProperties);

     }

  };

  // Manejar la selección de un producto Shopify
  const handleProductSelect = (product) => {
  // Verificar si el producto ya está vinculado a la tienda seleccionada
    const hasId = storeSelect.synced_products?.some(
      (item) => item.synced_product_id === product.id
    );
    console.log(hasId)
    if (hasId) {
      setProductHasVinculated(true);
      toastFunction(toast, "warn", `${t("toast-summary.theProduct")}${product.name} ${t("toast-summary.productIsVinculated")}`,  "", 4000);
    } else {
      setProductHasVinculated(false);
      toastFunction(toast, "success", `${t("toast-summary.theProduct")}${product.name} ${t("toast-summary.productIsNotVinculated")}`,  "", 4000);
    }
    // Aquí puedes añadir lógica adicional según sea necesario después de la verificación
    setProductSelect(product);
  };

  // Obtener productos de Shopify para una tienda
  const fetchShopifyProductsData = async (shopifyStore) => {
    setGlobalLoading(true);
    const body = {
      shopifyUrl: shopifyStore.store_url,
      tokenId: shopifyStore.product_sync_shopify_app_token,
    };
    try {
      // Realizar la solicitud para obtener productos de Shopify
      const resultFetchProductsShopify = await fetchShopifyProductConnection(
        body
      );
    
      // Actualizar el estado con los productos extraídos
      const newArrayWithProductUsableProperties = extractProductShopifyProperties(
        resultFetchProductsShopify?.data.products
      );

      setProductsData(newArrayWithProductUsableProperties);

      if (resultFetchProductsShopify.status === 200) {
        toastFunction(toast, "success", t("succefullProductsConnection"),  "", 4000);
      }
      if (resultFetchProductsShopify?.data.productsLength === 0) {
        toastFunction(toast,  "warn", t("noProductsInShopify"),  "", 4000 );
      }
      return true;
    } catch (error) {
      setProductsData([]);
      setProductHasVinculated(true);
      console.error(error);
      toastFunction(toast, "error", t("toast-summary.errorProductsConnection"), "", 4000 );
      return false;
    }
  };
  // Extraer propiedades necesarias de los productos
  const extractProductShopifyProperties = (products) => {
    const extractedData = products.map((product) => {
      const { title, id } = product;
      return { name: title, id: id }; // Cambia los nombres de las claves según tu preferencia
    });
    return extractedData;
  };

  // Función para vincular un producto de Shopify a una tienda
  const vinculateProduct = async (e) => {
    e.preventDefault();
    setGlobalLoading(true);
    
    const { categories_ids } = productData || {};
    const { platform, is_a_buying_club } = storeSelect || {};
    const isShoppexStore = platform?.code === 1;
    
    let resultVinculation;
    let newrelatedSyncedShoppexProductValues = {
      ...relatedSyncedShoppexProductValues,
      comparative_price: comparativePriceValue,
      categories_ids,
    };
  
    if (platform?.code === 2 && productSelect.length === 0) {
      toastFunction(toast, "success", "Para eliminar una vinculacion con Shopify es necesario seleccionar el producto", "", 3000);
      setGlobalLoading(false);
      return;
    }
  
    // Verificar si la tienda está vinculada con el producto relacionado
    const { vinculatedStore, vinculatedProduct } = await findStoreAndVinculatedProductInShoppexStore(productData?.related_synced_id, sellerStores);
  
    if (is_a_buying_club) {
      newrelatedSyncedShoppexProductValues.premium_service_fee = premiumServiceFeeValue - relatedSyncedShoppexProductValues.product_cost;
    }
  
    console.log("vinculatedStore", vinculatedStore, shoppexStoreData);
  
    const newFixedprices = isShoppexStore && vinculatedProduct ? {
      ...shoppexStoreData.fixed_prices,
      [newrelatedSyncedShoppexProductValues.product_id]: newrelatedSyncedShoppexProductValues.with_fixed_price,
    } : {};
  
    try {
      if (!vinculatedStore) {
        // Si no está vinculada, agregar el nuevo producto sincronizado a la tienda del seller
        if (isShoppexStore) {
          await updateDocument("stores", shoppexStoreData.id, { fixed_prices: newFixedprices });
          newrelatedSyncedShoppexProductValues = { ...newrelatedSyncedShoppexProductValues, sections: [] };
        }
  
        resultVinculation = await createSubcollectionDocument(
          "stores",
          shoppexStoreData.id,
          "related_products",
          newrelatedSyncedShoppexProductValues.id,
          newrelatedSyncedShoppexProductValues
        );
      } else {
        if (isShoppexStore) {
          await updateDocument("stores", shoppexStoreData.id, { fixed_prices: newFixedprices });
          resultVinculation = await updateSubcollectionDocument(
            "stores",
            vinculatedStore.id,
            "related_products",
            vinculatedProduct.id,
            newrelatedSyncedShoppexProductValues
          );
        }
      }
  
      if (resultVinculation) {
        toastFunction(toast, "success", t("succefullVinculate"), "", 4000);
        onClose();
      }
    } catch (error) {
      console.error("Error during product vinculation:", error);
      toastFunction(toast, "error", "Error al vincular el producto.", "", 4000);
    } finally {
      setGlobalLoading(false);
    }
  };
  
  console.log("relatedSyncedShoppexProductValues", relatedSyncedShoppexProductValues)

  const handleToggleSwitchFixedPrice = (e) => {
    // Actualiza el estado utilizando el anterior valor de product_is_active
    setRelatedSyncedShoppexProductValues((prevValues) => ({
            ...prevValues,
            with_fixed_price: e.value,

        }));
  };

  const handleToggleSwitchIsActive = (e) => {
    // Actualiza el estado utilizando el anterior valor de product_is_active
    setRelatedSyncedShoppexProductValues((prevValues) => ({
            ...prevValues,
            is_active: e.value,

        }));
  };

  const onBlurSetFixedPrice = () => {
    if( relatedSyncedShoppexProductValues.fixed_price <= productData.commercial_partner_price_with_VAT){
        const sellerRevenue = relatedSyncedShoppexProductValues.suggested_retail_price - productData.commercial_partner_price_with_VAT
        toastFunction(toast, "warn", "El precio minimo es el precio del proveedor", "", 4000);
        setRelatedSyncedShoppexProductValues((prevValues) => ({
            ...prevValues,
            fixed_price: productData.suggested_retail_price,
            basic_service_fee: sellerRevenue,
        }));
      }
  };


  useEffect(() => {
    const fetchVinculatedStore = async () => {
      setGlobalLoading(true);
      try {
        // SI el producto está marcado con un id de vinculación, buscamos la tienda
        console.log("productData", productData?.related_synced_id, sellerStores);
        if (productData?.related_synced_id) {
          // Encontramos entre las tiendas del seller el producto con el que coincidan los ids de la sincronización y la tienda
          const { vinculatedStore, vinculatedProduct } = await findStoreAndVinculatedProductInShoppexStore(productData.related_synced_id, sellerStores);
          
          console.log("vinculatedStore", vinculatedStore);
          // setSyncedProductId(vinculatedProduct?.id);
          
          if (vinculatedStore) {
            let vinculatedProductSelected
            toastFunction(toast, "info", t("Estamos obteniendo tus productos"), "", 4000);
            setStoreSelect(vinculatedStore);
            if (vinculatedStore?.platform.code === 1) {  // Si la plataforma es Shoppex buscamos en la coleccion 'stores' el objeto de la vinculacion 

              // Traemos los productos que esten relacionado con el businessId del usuarios
              fetchShoppexSellerProducts(userData?.business_id)
              const shoppexStoreData = await getDocumentById("stores", vinculatedStore.id)

              // Id del objeto de la vinculacion de la tienda shoppex en "stores"
              // Construimos el objeto de la vinculacion para que coincida con algun objeto de la lista de productos que se obtienen en `fetchShoppexSellerProducts`
                vinculatedProductSelected = {
                  id: vinculatedProduct?.synced_product_id,
                  name: vinculatedProduct?.synced_product_name,
                  suggested_retail_price: vinculatedProduct?.suggested_retail_price,
                  commercial_partner_price_with_VAT:  vinculatedProduct?.product_cost
              };
              setShoppexStoreData(shoppexStoreData);
              setDisabledProductAndStoreSelect(true);
              setProductSelect(vinculatedProductSelected);
              // console.log("Objeto vinculado", findedVinculationShoppexObject)
              if (shoppexStoreData?.is_a_buying_club) {
                setPremiumServiceFeeValue(vinculatedProduct.premium_service_fee + vinculatedProduct?.product_cost)
              }
              setComparativePriceValue(vinculatedProduct?.comparative_price)
              setRelatedSyncedShoppexProductValues(vinculatedProduct);
    
            } else if (vinculatedStore.platform.code === 2) {
             vinculatedProductSelected = {
                id: vinculatedProduct?.synced_product_id,
                name: vinculatedProduct?.synced_product_name,
              };
              // setProductHasVinculated(true);
              setDisabledProductAndStoreSelect(true);
              fetchShopifyProductsData(vinculatedStore);
              setProductSelect(vinculatedProductSelected);
            }
          }
        }
      } catch (error) {
        console.error("Error emparejando los datos del producto vinculado con la tienda Shoppex:", error);
      } finally {
        // Asegúrate de que setGlobalLoading se llame independientemente del éxito o fallo
        setGlobalLoading(false);
      }
      // Actualizar la bandera de primer render después del primer render
      isFirstRender.current = false;
    };
  
    fetchVinculatedStore();
  }, [userData]);
  

  console.log(storeSelect)
  return (
    <form onSubmit={vinculateProduct}>
      <div className="mt-8">
        <CustomSelect
          floatLabel={true}
          optionLabel={"name"}
          options={sellerStores}
          value={storeSelect}
          placeholder={t("input-placeholders.selectStore")}
          onChange={(e) => handleStoreSelect(e.target.value)}
          required={true}
          disabled={disabledProductAndStoreSelect}
        />
        
        {/* Que solo aparezca el select del producto cuando la tienda es shopify */}
        { storeSelect && storeSelect?.platform?.code  === 2 && (<CustomSelect
          floatLabel={true}
          optionLabel={"name"}
          options={productsData}
          value={productSelect}
          placeholder={t("input-placeholders.selectProduct")}
          onChange={(e) => handleProductSelect(e.target.value)}
          required={true}
          disabled={productsData?.length === 0 || disabledProductAndStoreSelect}
        />)}

        {/* Que solo aparezca los campos adicionales para la vinculacion cuando la tienda es shoppex */}
        {(storeSelect?.platform?.code === 1 && !productHasVinculated)&&(
           <>
            <CustomInputText
              floatLabel={true}
              type={"text"}
              value={relatedSyncedShoppexProductValues?.custom_title}
              placeholder={t("input-placeholders.customTitle")}
              onChange={(e) => setRelatedSyncedShoppexProductValues((prevValues) => ({
                  ...prevValues,
                  custom_title: e?.target?.value,
              }))}
              disabled={globalLoading}
              required={true}
            />
               <CustomInputNumber
                required={false}
                floatLabel={true}
                value={comparativePriceValue}
                min={0}
                prefix={"$ "}
                onChange={(e) => {
                  const comparativePrice = e.value ;
                  setComparativePriceValue(comparativePrice)
                }}
                // max={walletUser.balance_available}
                placeholder={t("input-placeholders.comparativePrice")}
                disabled={!storeSelect?.is_a_buying_club}
              />
             { storeSelect?.is_a_buying_club && (  
              <CustomInputNumber
                required={false}
                floatLabel={true}
                value={premiumServiceFeeValue}
                min={0}
                prefix={"$ "}
                onChange={(e) => {
                  const premiumValue = e.value ;
                  setPremiumServiceFeeValue(premiumValue)
                }}
                placeholder={t("input-placeholders.premiumServiceFee")}
                disabled={!storeSelect?.is_a_buying_club}
              />
              )}
         
            <CustomInputNumber
              required={true}
              floatLabel={true}
              value={relatedSyncedShoppexProductValues?.fixed_price}
              prefix={"$ "}
              placeholder={t("input-placeholders.fixedPrice")}
              onChange={(e) => {
                const sellerRevenue = e.value -( productData?.commercial_partner_price_with_VAT_with_service_fee);
                setRelatedSyncedShoppexProductValues((prevValues) => ({
                  ...prevValues,
                  fixed_price: e.value,
                  basic_service_fee: sellerRevenue,
                }));
              }}
              onBlur={onBlurSetFixedPrice}
              disabled={globalLoading}
            />
            <CustomInputNumber
              required={true}
              floatLabel={true}
              value={relatedSyncedShoppexProductValues?.product_cost}
              min={0}
              prefix={"$ "}
              placeholder={t("input-placeholders.productCost")}
              disabled={true}
            />
            <CustomInputNumber
              required={true}
              floatLabel={true}
              value={relatedSyncedShoppexProductValues?.basic_service_fee}
              min={0}
              prefix={"$ "}
              // max={walletUser.balance_available}
              placeholder={t("input-placeholders.basicServiceFee")}
              disabled={true}
            />
           
          <span className="flex items-center gap-4 mt-4 mb-4">
            <CustomInputSwitch
                checked={relatedSyncedShoppexProductValues?.with_fixed_price}
                name={"with_fixed_price"}
                onChange={(e) => handleToggleSwitchFixedPrice(e)}
                disabled={globalLoading}
            /> Fijar el precio
          </span>
          <p>{t("fixedPriceMessage")}</p>
          <span className="flex items-center gap-4 mt-4 mb-4">
            <CustomInputSwitch
                checked={relatedSyncedShoppexProductValues?.is_active}
                name={"is_active"}
                onChange={(e) => handleToggleSwitchIsActive(e)}
                disabled={globalLoading}
            /> Activar el producto
          </span>
           </>
        )}
      </div>
      <div className="mt-12 grid gap-4">
        <MainButton
          type={"onSubmit"}
          label={t("vinculateProduct")}
          autoFocus
          loading={globalLoading}
          disabled={globalLoading  || productHasVinculated || (storeSelect?.platform?.code === 2 && disabledProductAndStoreSelect)
          }
        />
         { productData?.related_synced_id && (
            <DeleteButton
                type={"button"}
                label={t("Deshacer vinculacion")}
                loading={globalLoading}
                onClick={() => setVisibleConfirmDialog(true)}
                disabled={globalLoading}

            />)}
      </div>
        <CustomConfirmDialog
          visible={visibleConfirmDialog}
          onHide={() => setVisibleConfirmDialog(false)}
          header={t("confirmDialog-deleteVinculation.header")}
          message={t("confirmDialog-deleteVinculation.message")}
          icon="pi pi-question-circle"
          accept={handleDeleteVinculation}
          reject={() => setVisibleConfirmDialog(false)}
          acceptLabel={t("confirmDialog-deleteVinculation.acceptLabel")}
          rejectLabel={t("confirmDialog-deleteVinculation.rejectLabel")}
        />
    </form>
  );
};
VinculateProductForm.propTypes = {
  selectedSellerStore: PropTypes.object,
  userData: PropTypes.object,
  labelSuccess: PropTypes.string,
  labelDelete: PropTypes.string,
  onClose: PropTypes.func,
  productData: PropTypes.object,
  toast: PropTypes.object,
};
export default VinculateProductForm;
