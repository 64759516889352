import PropTypes from "prop-types";
import styles from "./ShoppexStoreForm.module.css";
import { useTranslation } from "react-i18next";
import { generateRandomId } from "../../utils/generateRandomId.js";
import { useEffect, useState } from "react";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText.jsx";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect.jsx";
import { Divider } from "primereact/divider";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext.jsx";
import HorizontalScrollList from "../HorizontalScrollList/HorizontalScrollList.jsx";
import CircleCard from "../CircleCard/CircleCard.jsx";
import BadgetCard from "../BadgetCard/BadgetCard.jsx";
import BannerCard from "../BannerCard/BannerCard.jsx"
import { BiPlusCircle } from "react-icons/bi";
import CustomModal from "../CustomModal/CustomModal.jsx";
import DetailBannerForm from "../DetailBannerForm/DetailBannerForm.jsx";
import DetailBadgetForm from "../DetailBadgetForm/DetailBadgetForm.jsx";
import DetailGroupForm from "../DetailGroupForm/DetailGroupForm.jsx";
import toastFunction from "../../utils/toastFunction.js";
import MainButton from "../MainButton/MainButton.jsx";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import CustomInputSwitch from "../FormComponents/CustomInputSwitch/CustomInputSwitch.jsx";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD.js";
import CustomConfirmDialog from "../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog.jsx";
import DeleteButton from "../DeleteButton/DeleteButton.jsx";
import { DESKTOP } from "../router/paths.js";
import sortObjectsByPosition from "../../utils/sortObjectsByPosition.js";
import CustomChips from "../FormComponents/CustomChips/CustomChips.jsx";
import { REVIEWDocument } from "../router/paths.js"
import ShoppexStoreColors from "../ShoppexStoreColors/ShoppexStoreColors.jsx";
import processMediaArray from "../../utils/processMediaArray.js";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ProgressSpinner } from "primereact/progressspinner";
import { fetchFromCloudFunction } from "../../services/cloudFunctinons/fetchFromCloudFunction.js";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase/firebase.js";
import CustomTable from "../CustomTable/CustomTable.jsx";



const ShoppexStoreForm = ({onClose, selectedSellerStore, userData, categoriesData, toast}) => {
  const { t } = useTranslation("Stores");
  const { globalLoading, setGlobalLoading} = useLoaderContext();
  const { languages, currencyTypes } = useGlobalContext();

  let hasEdit;
  if (selectedSellerStore) {
      hasEdit = !!Object.keys(selectedSellerStore)?.length;
  }
  const {
    updateDocument,
    createDocument,
    deleteDocument,
    addToArray,
    removeFromArray,
    updateArray,
    deleteFolderStorageAndContents
    ,getDocumentById 
  } = useFirebaseCRUD();


  const shoppexStoreInitialValues =  {
      id: selectedSellerStore?.id || generateRandomId(), // Identificador unico de la tienda
      counter_store: selectedSellerStore?.counter_store || 1000,
      created_at:  selectedSellerStore?.created_at || new Date(), // Fecha de creacion del wallet, debe crease justo con la crreacion del aliado comercial
      is_a_buying_club: selectedSellerStore?.is_a_buying_club || false, // Booleano que especifica si esta tienda tiene funcionalidades de club de compra
      // domain: selectedSellerStore?.domain || null, 
      domain_name: selectedSellerStore?.domain_name || null, // Dominio de la tienda
      fixed_prices: {}, // Objeto en el que estaran todos los ids de los productos que tienen el precio fijado
      related_seller_id: selectedSellerStore?.related_seller_id || userData?.uid, // UID del Seller user
      related_business_id: selectedSellerStore?.related_business_id || userData?.business_id, // businees_id del seller relacionado
      related_reviews_id: selectedSellerStore?.related_reviews_id || null,
      name: selectedSellerStore?.name || null, // nombre de la tienda
      default_currency: selectedSellerStore?.default_currency, // Moneda de la tienda por defecto
      default_language: selectedSellerStore?.default_language, // Idioma de la tienda por defecto
      fb_pixel_id: selectedSellerStore?.fb_pixel_id || null, // Identificador del pixel de facebook
      tt_pixel_id: selectedSellerStore?.tt_pixel_id || null, // Identificador del pixel de tikto
      pt_pixel_id: selectedSellerStore?.pt_pixel_id || null, // Identificador del pixel de pinteres
      tb_pixel_id: selectedSellerStore?.tb_pixel_id || null, //Identificador de pixel de Taboola
      gg_tag_id: selectedSellerStore?.gg_tag_id || null, // Identificador del tag de google
      related_products: selectedSellerStore?.related_products || [],  // Array de objetos, Cada objeto, representa un producto asignado a la tienda
      related_products_landing_content: selectedSellerStore?.related_products_landing_content || [], // Array de obejtos. Cada objeto representa para cada producto las secciones del landing page de un producto  
      main_banner: selectedSellerStore?.main_banner || [],
      main_badges: selectedSellerStore?.main_badges || [], // Array de objetos, Cada objeto representa la info del badge
      main_groups: selectedSellerStore?.main_groups || [],
      tag_ids: selectedSellerStore?.tag_ids || [], // Tags de la tienda
      is_active: selectedSellerStore?.is_active || false,
      store_styles: selectedSellerStore?.store_styles || {}
  };


  const [ shoppexStoreValues, setShoppexStoreValues ] = useState(shoppexStoreInitialValues);
  // console.log("shoppexStoreValues", shoppexStoreValues)
  const [ selectedLanguage, setSelectedLanguage ] = useState({});
  const [ selectedCurrency, setSelectedCurrency ] = useState({});
  
  const [visibleBannerModal, setVisibleBannerModal] = useState(false);
  const [visibleBadgetModal, setVisibleBadgetModal] = useState(false);
  const [visibleGroupModal, setVisibleGroupModal] = useState(false);
  
  const [ visibleConfirmDeleteStoreModal, setVisibleConfirmDeleteStoreModal ] = useState(false);

  const [ selectedGroup, setSelectedGroup ] = useState(null);
  const [ valuesTags, setValuesTags ] = useState([]);
  const [ valuesTagsOnBlur, setValuesTagsOnBlur ] = useState([]);

  const [checkAndSetDomainResponse, setCheckAndSetDomainResponse] = useState(false);
  

  const handleInputChange = (fieldName, e) => {
    let value = e;
    if(fieldName === "default_currency") {
      setShoppexStoreValues((prevData) => ({
        ...prevData,
        [fieldName]: value.code,
      }));
      setSelectedCurrency(value);
    } else if(fieldName === "default_language") {
      setShoppexStoreValues((prevData) => ({
        ...prevData,
        [fieldName]: value.code,
      }));
      setSelectedLanguage(value);
    } else if( fieldName === "domain_name") {
      value = e.target.value;
      setShoppexStoreValues((prevData) => ({
        ...prevData,
        [fieldName]: value,
      }));
    } else {
      setShoppexStoreValues((prevData) => ({
        ...prevData,
        [fieldName]: value,
      }));
    }
  };

  const handleSelectGroup = (group, groupType) => {
    setSelectedGroup(group);
    switch (groupType) {
      case "banner":
        setVisibleBannerModal(true);
        break;
      case "badget":
        setVisibleBadgetModal(true);
      break;
      case "group":
        setVisibleGroupModal(true);
      break;
    }
  };
  // Controlador que recibe el evento, los strings que se digitan y que se separan con una "," ej ["1", "2", "4"]
  const handleChipsTagsSelect = (e) => {
    // addMessage();
    setValuesTags(e.value)
    setShoppexStoreValues((prevData) => ({
      ...prevData,
      tag_ids: e.value,
    }));
  };

  // Manejador del evento cuando se pierde el foco del componente Chips de los Tags
  const handleOnBlurChipTags = () => {
    // msgs.current.clear();
    if (valuesTagsOnBlur == valuesTags) {
      setValuesTagsOnBlur(valuesTags);
      toastFunction(toast, "warn", t("toast-summary.failChip"), "", 2000);
    }
    // Para que no se repitan los tags
    const noRepeteadedTags = new Set(valuesTags);
    const noRepeteadedTagsArray = Array.from(noRepeteadedTags);
    setValuesTags(noRepeteadedTagsArray);
    setShoppexStoreValues((prevData) => ({
      ...prevData,
      tag_ids: noRepeteadedTagsArray,
    }));
  };

  const openNewBannerFormModal = () => {
    setVisibleBannerModal(true)
    setSelectedGroup(null);
  }
  const openNewBadgetFormModal = () => {
    setVisibleBadgetModal(true)
    setSelectedGroup(null);
  };
  
  const openNewGroupFormModal = () => {
    setVisibleGroupModal(true)
    setSelectedGroup(null);
  };
  
  const saveShoppexStore = async (e) => {
    setGlobalLoading(true);
      e.preventDefault();    

    // 1. procesamos el contenida multimedia de toda la tienda que esta base64 
    await processMediaArray(shoppexStoreValues.main_banner)
    await processMediaArray(shoppexStoreValues.main_groups)
    let resultUpdateArray;
    let resultUpdateSeller;
  
    if ( hasEdit ) { 
      // Encontramos la tienda que queremos editar o reemplazar con el contenido nuevo
      const findedUserShoppexStore = userData?.seller_stores?.find((store) => store?.id === shoppexStoreValues?.id)
      resultUpdateArray = await updateDocument("stores", shoppexStoreValues.id, {...shoppexStoreValues})
      // Si hay cambios en el nombre de la tienda durante la edicion lo actualizamos 
      if (selectedSellerStore.name !== shoppexStoreValues.name ) {
        const newUserShoppexStore = {...findedUserShoppexStore, name: shoppexStoreValues.name}
        resultUpdateSeller = await updateArray("seller_users", userData.uid, "seller_stores", findedUserShoppexStore, newUserShoppexStore)
      } else {
        resultUpdateSeller = true;
      }
    } else {
      // Si se esta creando una nueva tienda, creamos el objeto de la vinculacion de la tienda a el documento del seller, y creamos el documento de los comentarios
      const relatedSellerShoppexStore = {
        id: shoppexStoreValues.id,
        name: shoppexStoreValues.name,
        platform: { code: 1, name: "Supplaii"},
        store_url: null,
        synced_products: [],
      };
      const reviewValues = {...REVIEWDocument};
      reviewValues.store_id = shoppexStoreValues.id;
      reviewValues.store_name = shoppexStoreValues.name;
      resultUpdateArray = await createDocument("stores", shoppexStoreValues.id, shoppexStoreValues);
      resultUpdateArray = await createDocument("reviews", reviewValues.store_id, reviewValues);
      resultUpdateSeller = await addToArray("seller_users", userData.uid, "seller_stores", relatedSellerShoppexStore);
    }
    if( resultUpdateArray && resultUpdateSeller ) {
      toastFunction(toast, "success", "Tienda Shoppex guardada con exito", "", 3000)
      onClose();
    } else {
      toastFunction(toast, "error", "Error al guardar los datos de la Tienda Shoppex", "", 3000)
    }
    setGlobalLoading(false)
  };

  const handleDeleteShoppexStore = async () => {
    setGlobalLoading(true)
    const reviewsDocument = await getDocumentById("reviews", shoppexStoreValues.id);
    if (reviewsDocument && reviewsDocument.reviews.length === 0) {
      try {
       await deleteDocument("reviews", shoppexStoreValues.id)     
      } catch (error) {
        console.error(error);
      }
    }
    const findedUserShoppexStore = userData.seller_stores.find((store) => store.id === shoppexStoreValues.id)
    const pathToDelete = `shoppexStores/${selectedSellerStore.id}/`;
    await deleteFolderStorageAndContents(pathToDelete);
    const resultDeleteShoppexStore = await deleteDocument("stores", shoppexStoreValues?.id)    
    const resultRemoveUserShoppexStore = await removeFromArray("seller_users", userData.uid, "seller_stores", findedUserShoppexStore);
    if ( resultDeleteShoppexStore && resultRemoveUserShoppexStore) {
      toastFunction( toast, "success", "Tienda eliminada con exito", "", 3000);
      onClose();
    }else {
      toastFunction( toast, "error", "Error al eliminar la tienda Shoppex", "", 3000);
    }
    setGlobalLoading(false)
  };

  
  useEffect( function findMatchingCurrencyLenguagesAndTags() {
    if (selectedSellerStore) {
      const findedCurrency = currencyTypes.find((currency) => currency.code === shoppexStoreValues?.default_currency)
      const findedLanguage = languages.find((language) => language.code === shoppexStoreValues?.default_language)
      setValuesTags(shoppexStoreValues?.tag_ids);
      setSelectedCurrency(findedCurrency)
      setSelectedLanguage(findedLanguage)
    }
  }, []);

  
  const handleCheckDomainConnection = async () => {
    setCheckAndSetDomainResponse(true);
    const body = {
      storeId: shoppexStoreValues.id,
      domain: shoppexStoreValues.domain_name, 
    };
    console.log("body,", body)
    const endpoint = "/shoppex/validate-domain-status"; 
    // Realizar la solicitud a la función en la nube

    const quoteResult = await fetchFromCloudFunction(endpoint, body, "POST");
    console.log(quoteResult)

    if (quoteResult?.data?.success !== true) {
      setCheckAndSetDomainResponse(false)
      toastFunction(toast, "error", quoteResult.data.message, "", 4000);

    } else if(quoteResult?.data?.message === "El dominio no está configurado correctamente. Por favor, elimina los siguientes registros en tu proveedor de dominio") {
      toastFunction(toast, "warn", quoteResult.data.message, "", 6000);
      setCheckAndSetDomainResponse(quoteResult?.data);

    } else {
      toastFunction(toast, "success", quoteResult.data.message, "", 4000);
      setCheckAndSetDomainResponse(quoteResult?.data);
    }
  };

  const handleSetDomain = async () => {
    setCheckAndSetDomainResponse(true);
    const setDomain = httpsCallable(functions, "setVercelDomain");
    try {
      const result = await setDomain({
        storeId: shoppexStoreValues.id,
        domain: shoppexStoreValues.domain_name, 
      });
      console.log("handleSetDomain, resultado despues del fetch", result, toast);
      return result;
    } catch (error) {
      console.error("Error llamando la función handleSetDomain:", error);
      toastFunction(toast, "error", error.code, "", 4000);
    } 
      setCheckAndSetDomainResponse(false)
  }

  const conflictDomainsColumns = [
    {   
        field: "name",
        header: t("table-headers.names"),
        filterable: false,
        sortable: false, 
    },
    {   
        field: "value",
        header: t("table-headers.domainValue"),
        filterable: false,
        sortable: false, 
    },
  ];

  const domainAddedSuccesfullColumns = [
    {   
        field: "name",
        header: t("table-headers.names"),
        filterable: false,
        sortable: false, 
    },
    {   
        field: "type",
        header: t("table-headers.domainType"),
        filterable: false,
        sortable: false, 
    },
    {   
      field: "value",
      header: t("table-headers.domainValue"),
      filterable: false,
      sortable: false, 
    },
  ];

    return (
      <>
        <form  onSubmit={saveShoppexStore}>
          <div className={`${styles.mainContainer}`}>
            {/* Primera Columna ESPACIO VACIO */}
            <div></div> 
            {/* Segunda Columna DATOS BASICOS*/}
            <div>
                <ShoppexStoreColors storeColorsValues={shoppexStoreValues?.store_styles || {}} setShoppexStoreValues={setShoppexStoreValues}/>
                <h4>Datos basicos</h4>
                <CustomInputText
                  floatLabel={true}
                  type={"text"}
                  value={shoppexStoreValues?.name}
                  placeholder={t("input-placeholders.name")}
                  onChange={(e) => handleInputChange("name", e.target.value)}
                  required={true}
                  disabled={globalLoading}
                />
                <CustomSelect
                  floatLabel={true}
                  optionLabel={"name"}
                  options={currencyTypes}
                  valueTemplate={""}
                  itemTemplate={""}
                  value={selectedCurrency}
                  placeholder={t("input-placeholders.defaultCurrency")}
                  onChange={(e) => handleInputChange("default_currency", e.target.value)}
                  required={true}
                  disabled={globalLoading}
                />
                <CustomSelect
                  floatLabel={true}
                  optionLabel={"name"}
                  options={languages}
                  valueTemplate={""}
                  itemTemplate={""}
                  value={selectedLanguage}
                  placeholder={t("input-placeholders.defaultLanguage")}
                  onChange={(e) => handleInputChange("default_language", e.target.value)}
                  required={true}
                  disabled={globalLoading}
                />
                <p className="mt-2 mb-2">Añade tags para tus productos</p>
                <div className="p-fluid">
                  <CustomChips
                    floatLabel={true}
                    style={{width: "100%"}}
                    value={valuesTags}  // Estado, array de strings
                    onChange={(e) => handleChipsTagsSelect(e)} // Controlador para setear los tags en los estados
                    onFocus={() => setValuesTagsOnBlur(valuesTags)} // Seteo los valores para comparar los valores que habian al entrar y salir
                    onBlur={() => handleOnBlurChipTags()} 
                    separator=","
                    placeholder={t("input-placeholders.chipTags")}
                    disabled={globalLoading}
                  />
                </div>

                <Divider />
                <h4>Tracking</h4>
                <CustomInputText
                  floatLabel={true}
                  type={"text"}
                  value={shoppexStoreValues?.fb_pixel_id}
                  placeholder={t("input-placeholders.fbPixelId")}
                  onChange={(e) => handleInputChange("fb_pixel_id", e.target.value)}
                  disabled={globalLoading}
                />
                <CustomInputText
                  floatLabel={true}
                  type={"text"}
                  value={shoppexStoreValues?.tt_pixel_id}
                  placeholder={t("input-placeholders.ttPixelId")}
                  onChange={(e) => handleInputChange("tt_pixel_id", e.target.value)}
                  disabled={globalLoading}
                />
                <CustomInputText
                  floatLabel={true}
                  type={"text"}
                  value={shoppexStoreValues?.pt_pixel_id}
                  placeholder={t("input-placeholders.ptPixelId")}
                  onChange={(e) => handleInputChange("pt_pixel_id", e.target.value)}
                  disabled={globalLoading}
                />
            </div>
            <Divider />
<div className="mt-4">

  <Accordion  activeIndex={false}>
    <AccordionTab header="Vincular dominio">
        <div>
           <CustomInputText
              floatLabel={true}
              type={"text"}
              value={shoppexStoreValues?.domain_name}
              placeholder={t("input-placeholders.domainName")}
              onChange={(e) => handleInputChange("domain_name", e)}
              required={true}
            />

          <div className="grid gap-4">
            <MainButton
              loading={globalLoading}
              type={"button"}
              label={t("handleSetDomain")}
              disabled={ globalLoading || !shoppexStoreValues?.domain_name}
              onClick={handleSetDomain}
            />
             <MainButton
              loading={globalLoading}
              type={"button"}
              label={t("checkDomain")}
              disabled={ globalLoading || !shoppexStoreValues?.domain_name}
              onClick={handleCheckDomainConnection}
            />
          </div>
          <div className="mt-8 flex justify-center">
           {checkAndSetDomainResponse === true  ? ( <ProgressSpinner />) : checkAndSetDomainResponse?.conflicts ? 
                <CustomTable
                  className="custom-table"
                  columns={conflictDomainsColumns}
                  data={checkAndSetDomainResponse}
                  action={() => {}}
                />
                : checkAndSetDomainResponse?.dnsConfig ? ( <CustomTable
                  className="custom-table"
                  columns={domainAddedSuccesfullColumns}
                  data={checkAndSetDomainResponse}
                  action={() => {}}
              />) :<></>}
          </div>
        </div>
    </AccordionTab>
  </Accordion>
</div>
            <span className={styles.subtitleMobile}>
              <Divider />
              <h4 >{t("banners")}</h4>
            </span>
            <div className={styles.plusIconContainer}>
              <span  disabled={globalLoading} className={`${styles.plusIcon}`} onClick={openNewBannerFormModal} >
                <BiPlusCircle />
              </span>
            </div>
            <div className={`${styles.containerGroupBanner}`}>  
               <span className={`${styles.subtitleDesktop}`}>
                <Divider />
                <h4>{t("banners")}</h4>
              </span>
              <HorizontalScrollList>
                  {sortObjectsByPosition(shoppexStoreValues?.main_banner).map((banner, index) => {
                      const coverTypeMedia = {
                        code: banner?.media_type === "video" ? 1 : 0,
                      }
                      return (
                        <div 
                          className="mr-4"
                          key={index} 
                        >
                          <BannerCard 
                            coverTypeMedia={coverTypeMedia} 
                            shoppexStoreId={shoppexStoreValues.id} 
                            data={banner} 
                            mediaType={DESKTOP}
                            onClick={handleSelectGroup} 
                            disabled={globalLoading} 
                          />
                        </div>
                      );
                  })}
              </HorizontalScrollList>
            </div>
            <span className={styles.subtitleMobile}>
              <Divider />
                <h4>{t("badgets")} </h4>
            </span>
            <div className={styles.plusIconContainer}>
              <span disabled={globalLoading} className={styles.plusIcon} onClick={openNewBadgetFormModal}>
                <BiPlusCircle />
              </span>
            </div>

            <div className={styles.containerGroupBadget}>  
               <span className={styles.subtitleDesktop}>
                <Divider />
                  <h4>{t("badgets")} </h4>
                </span>
              <HorizontalScrollList>
                {sortObjectsByPosition(shoppexStoreValues?.main_badges).map((badge, index) => (
                <div 
                    className="mr-4"
                    key={index} 
                  >
                  <BadgetCard
                    key={index}
                    data={badge}
                    Icon={badge?.icon}
                    isErasable={true}
                    bold_text={badge.bold_text}
                    regular_text={badge.regular_text}
                    color={badge.badge_color}
                    onClick={handleSelectGroup}
                    disabled={globalLoading}
                  />
                  </div>
                ))}
              </HorizontalScrollList>
            </div>
             <span className={styles.subtitleMobile}>
              <Divider />
                <h4>{t("groups")} </h4>
            </span>
            <div className={styles.plusIconContainer}>
              <span className={styles.plusIcon} disabled={globalLoading} onClick={openNewGroupFormModal}>
                <BiPlusCircle />
              </span>
            </div>
            <div className={styles.containerGroup}>  
              <span className={styles.subtitleDesktop}>
                <Divider />
                <h4>{t("groups")}</h4>
                </span>
              <HorizontalScrollList>
                {sortObjectsByPosition(shoppexStoreValues?.main_groups).map((group, index) => {
                  const coverTypeMedia = {
                        code: group?.media_type === "video" ? 1 : 0, 
                  }
                  return (<CircleCard
                    key={index}
                    coverTypeMedia={coverTypeMedia}
                    // img_url={group?.group_img_src}
                    img_url={group?.media_src}
                    img_alt={group?.img_alt}
                    img_placeholder={group?.img_url}
                    label={group?.group_title}
                    isEditable={true}
                    handleSelectGroup={handleSelectGroup}
                    group={group}
                    without_border={null}
                    out_circle_color={"--main-hard-color"}
                    disabled={globalLoading}
                    // isSelected={category === selectedCategory} // Pasar el estado de selección a cada CircleCard
                  />)
                  })}
              </HorizontalScrollList>
            </div>

          </div>
          
          <Divider />
           <div className={`${styles.containerSwitch} mt-12`}>
              <CustomInputSwitch
                checked={shoppexStoreValues.is_active}
                onChange={(e) => handleInputChange("is_active", e.target.value)}
                disabled={globalLoading}
              />
              <h5>Activar tienda</h5>
          </div>
          <div className="mt-12 grid gap-4">
            <MainButton
              loading={globalLoading}
              type={"submit"}
              label={hasEdit ? t("editStore") : t("createStore")}
              disabled={globalLoading}
            />
            {hasEdit && (
                <span >
                  <DeleteButton
                    type={"button"}
                    label={t("deleteStore")}
                    loading={globalLoading}
                    onClick={() => setVisibleConfirmDeleteStoreModal(true)}
                    disabled={globalLoading}
                  />
                </span>
              )}
          </div>
        
        </form>
       <CustomModal
        visible={visibleBannerModal}
        setVisible={setVisibleBannerModal}
        object={selectedGroup}
        // menu_option={{label:t('menu-options.home')}}
        editMode={t("editBanner")}
        createMode={t("addBanner")}
        content={
          <DetailBannerForm
            onClose={() => setVisibleBannerModal(false)}
            userData={userData}
            labelDelete={t("deleteBanner")}
            labelSuccess={t("createBanner")}
            toast={toast}
            categoriesData={categoriesData}
            selectedBanner={selectedGroup}
            storeTags={shoppexStoreValues?.tag_ids}
            shoppexStoreId={shoppexStoreValues?.id}
            t={t}
            shoppexStoreValues={shoppexStoreValues}
            setShoppexStoreValues={setShoppexStoreValues}
          />
        }
        onClose={() => setVisibleBannerModal(false)}
      />   
      <CustomModal
        visible={visibleBadgetModal}
        setVisible={setVisibleBadgetModal}
        object={selectedGroup}
        editMode={t("editBadget")}
        createMode={t("addBadget")}
        content={
          <DetailBadgetForm
            onClose={() => setVisibleBadgetModal(false)}
            userData={userData}
            labelDelete={t("deleteBadget")}
            labelSuccess={t("createBadget")}
            toast={toast}
            categoriesData={categoriesData}
            selectedBadget={selectedGroup}
            shoppexStoreId={shoppexStoreValues?.id}
            shoppexStoreValues={shoppexStoreValues}
            setShoppexStoreValues={setShoppexStoreValues}
            t={t}
          />
        }
        onClose={() => setVisibleBadgetModal(false)}
      />
       <CustomModal
        visible={visibleGroupModal}
        setVisible={setVisibleGroupModal}
        object={selectedGroup}
        editMode={t("editGroup")}
        createMode={t("addGroup")}
        content={
          <DetailGroupForm
            onClose={() => setVisibleGroupModal(false)}
            userData={userData}
            toast={toast}
            categoriesData={categoriesData}
            selectedGroup={selectedGroup}
            storeTags={shoppexStoreValues?.tag_ids}
            shoppexStoreId={selectedSellerStore?.id}
            shoppexStoreValues={shoppexStoreValues}
            setShoppexStoreValues={setShoppexStoreValues}
            t={t}
          />
        }
        onClose={() => setVisibleGroupModal(false)}
      />
       <CustomConfirmDialog
        visible={visibleConfirmDeleteStoreModal}
        onHide={() => setVisibleConfirmDeleteStoreModal(false)}
        header={t("confirmDialog-deleteStore.header")}
        message={t("confirmDialog-deleteStore.message")}
        icon="pi pi-question-circle"
        accept={handleDeleteShoppexStore}
        reject={() => setVisibleConfirmDeleteStoreModal(false)}
        acceptLabel={t("confirmDialog-deleteStore.acceptLabel")}
        rejectLabel={t("confirmDialog-deleteStore.rejectLabel")}
      />
      </>
    
    );
}

ShoppexStoreForm.propTypes = {
  selectedSellerStore: PropTypes.object,
  userData: PropTypes.object,
  categoriesData: PropTypes.array,
  toast: PropTypes.object,
  onClose: PropTypes.func,
  storesData: PropTypes.array,
};
export default ShoppexStoreForm;
